import { useCallback } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Modal, Popover } from 'antd'
import { PlusCircleOutlined, QuestionOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import QuestionsFlowLevel from 'src/components/QuestionsFlowLevel'
import { ActionsRow } from 'src/common/styled'
import { InfoIcon } from './styled'

const QuestionsFlowStep = props => {
  const {
    intl,
    questionsFlow,
    questions,
    hasAssociation,
    addLevel,
    updateLevel,
    deleteLevel,
    onNext
  } = props

  const firstLevel = questionsFlow[0]
  const lastLevel = questionsFlow[questionsFlow.length - 1]

  const isAddLevelDisabled =
    lastLevel.questions.some(q => !q.id) ||
    lastLevel.questions.some(q => q.answers.some(a => a.length === 0))
  const isNextDisabled =
    firstLevel.questions.some(q => !q.id) ||
    firstLevel.questions.some(q => q.answers.some(a => a.length === 0))

  const updateLevelCallback = useCallback(
    (index, qfLevel) => {
      const needConfirm = questionsFlow.find((_, i) => i === index + 1)
        ? true
        : false

      if (needConfirm) {
        return Modal.confirm({
          title: intl.formatMessage(
            { id: 'questionsFlow.confirm.edit.title' },
            { level: qfLevel.level }
          ),
          content: intl.formatMessage({
            id: `questionsFlow.confirm.edit.content${
              hasAssociation ? '.withAssociation' : ''
            }`
          }),
          okText: intl.formatMessage({
            id: 'questionsFlow.confirm.edit.okText'
          }),
          cancelText: intl.formatMessage({
            id: 'questionsFlow.confirm.edit.cancelText'
          }),
          onOk: () => updateLevel(index, qfLevel)
        })
      }

      updateLevel(index, qfLevel)
    },
    [intl, questionsFlow, hasAssociation, updateLevel]
  )

  const deleteLevelCallback = useCallback(
    index => {
      const level = index + 1
      return Modal.confirm({
        title: intl.formatMessage(
          { id: 'questionsFlow.confirm.delete.title' },
          { level }
        ),
        content: intl.formatMessage(
          {
            id: `questionsFlow.confirm.delete.content${
              hasAssociation ? '.withAssociation' : ''
            }`
          },
          { level }
        ),
        okText: intl.formatMessage({
          id: 'questionsFlow.confirm.delete.okText'
        }),
        cancelText: intl.formatMessage({
          id: 'questionsFlow.confirm.delete.cancelText'
        }),
        onOk: () => deleteLevel(index)
      })
    },
    [intl, hasAssociation, deleteLevel]
  )

  return (
    <>
      <h3>
        <FormattedMessage id={'salesPlan.step.title.questionsFlow'} />
        <Popover
          content={intl.formatMessage({ id: 'questionsFlow.popover.info' })}
          placement={'right'}
          trigger={'click'}
          overlayStyle={{ maxWidth: 500 }}>
          <InfoIcon size={20} icon={<QuestionOutlined />} />
        </Popover>
      </h3>
      {questionsFlow.map((qfLevel, index) => (
        <QuestionsFlowLevel
          key={index}
          qfLevel={qfLevel}
          questions={questions}
          isDeletable={index > 0}
          onUpdate={qfLevel => updateLevelCallback(index, qfLevel)}
          onDelete={() => deleteLevelCallback(index)}
        />
      ))}
      <ActionsRow>
        <Button
          type={'link'}
          icon={<PlusCircleOutlined />}
          disabled={isAddLevelDisabled}
          onClick={addLevel}>
          <FormattedMessage id={'questionsFlow.button.addLevel'} />
        </Button>
      </ActionsRow>
      <ActionsRow justify={'end'}>
        <Button disabled={isNextDisabled} onClick={onNext}>
          <FormattedMessage id={'salesPlan.button.goToStepProducts'} />
        </Button>
      </ActionsRow>
    </>
  )
}
export default injectIntl(QuestionsFlowStep)
