import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form, Input } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import {
  ActionsRow,
  DynamicFieldContent,
  FormItem,
  BorderedTabs,
  BorderedTabPane
} from 'src/common/styled'
import { FormItemCard } from './styled'

const BasicInfoForm = props => {
  const { intl, initialValues, onSubmit, onValuesChange } = props
  const [formValues, setFormValues] = useState(initialValues)
  const isSubmitDisabled = Object.values(formValues).some(value =>
    Array.isArray(value) ? value.includes(undefined) : !value
  )

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(initialValues)
    setFormValues(initialValues)
  }, [form, initialValues])

  const onValuesChangeCallback = useCallback(
    (_, allValues) => {
      setFormValues(allValues)
      onValuesChange(allValues)
    },
    [onValuesChange]
  )

  const renderFormList = useCallback(
    (fields, { add, remove }, fieldName) => {
      return (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <FormItem
              key={key}
              label={
                index === 0
                  ? intl.formatMessage({
                      id: `basicInfo.form.label.${fieldName}`
                    })
                  : ''
              }
              required={index < 1}>
              <DynamicFieldContent>
                <Form.Item name={[name, 'text']} {...restField} noStyle>
                  <Input />
                </Form.Item>
                {fields.length > 1 ? (
                  <DeleteOutlined onClick={() => remove(name)} />
                ) : null}
              </DynamicFieldContent>
            </FormItem>
          ))}
          <FormItem>
            <Button
              type={'link'}
              icon={<PlusCircleOutlined />}
              onClick={() => add()}>
              <FormattedMessage id={`basicInfo.button.${fieldName}`} />
            </Button>
          </FormItem>
        </>
      )
    },
    [intl]
  )

  const renderTabs = useCallback(() => {
    const tabs = []

    for (let i = 1; i < 5; i++) {
      tabs.push(
        <BorderedTabPane
          key={i}
          tab={intl.formatMessage({ id: `basicInfo.tab.q${i}Features` })}
          forceRender>
          <Form.List name={`q${i}Features`}>
            {(fields, { add, remove }) =>
              renderFormList(fields, { add, remove }, `q${i}Features`)
            }
          </Form.List>
        </BorderedTabPane>
      )
    }

    return tabs
  }, [intl, renderFormList])

  return (
    <Form
      form={form}
      layout={'vertical'}
      initialValues={initialValues}
      onFinish={onSubmit}
      onValuesChange={onValuesChangeCallback}>
      <FormItemCard>
        <h2>
          <FormattedMessage id={'basicInfo.title.name'} />
        </h2>
        <FormItem
          name={'name'}
          label={intl.formatMessage({ id: 'basicInfo.form.label.name' })}
          required={true}>
          <Input />
        </FormItem>
      </FormItemCard>
      <FormItemCard>
        <h2>
          <FormattedMessage id={'basicInfo.title.benefits'} />
        </h2>
        <Form.List name={'benefits'}>
          {(fields, { add, remove }) =>
            renderFormList(fields, { add, remove }, 'benefits')
          }
        </Form.List>
      </FormItemCard>
      <FormItemCard>
        <h2>
          <FormattedMessage id={'basicInfo.title.preSaleAdvice'} />
        </h2>
        <Form.List name={'preSaleAdvice'}>
          {(fields, { add, remove }) =>
            renderFormList(fields, { add, remove }, 'preSaleAdvice')
          }
        </Form.List>
      </FormItemCard>
      <FormItemCard>
        <h2>
          <FormattedMessage id={'basicInfo.title.postSaleAdvice'} />
        </h2>
        <Form.List name={'postSaleAdvice'}>
          {(fields, { add, remove }) =>
            renderFormList(fields, { add, remove }, 'postSaleAdvice')
          }
        </Form.List>
      </FormItemCard>
      <FormItemCard>
        <h2>
          <FormattedMessage id={'basicInfo.title.quartileFeatures'} />
        </h2>
        <BorderedTabs type={'card'} size={'small'}>
          {renderTabs()}
        </BorderedTabs>
      </FormItemCard>
      <ActionsRow justify={'end'}>
        <Form.Item>
          <Button htmlType="submit" disabled={isSubmitDisabled}>
            <FormattedMessage id={'salesPlan.button.goToStepQuestions'} />
          </Button>
        </Form.Item>
      </ActionsRow>
    </Form>
  )
}
BasicInfoForm.propTypes = {
  goToSalesPlans: PropTypes.func,
  onSubmit: PropTypes.func,
  onValuesChange: PropTypes.func
}
export default injectIntl(BasicInfoForm)
