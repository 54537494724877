import PropTypes from 'prop-types'
import { Card as AntdCard, Typography } from 'antd'

const Card = props => {
  const { title, description, imgUrl, actions } = props
  return (
    <AntdCard
      cover={imgUrl ? <img src={imgUrl} alt={title} /> : false}
      actions={actions}>
      <AntdCard.Meta
        title={
          <Typography.Text ellipsis={{ tooltip: title }}>
            {title}
          </Typography.Text>
        }
        description={description}
      />
    </AntdCard>
  )
}
Card.propTypes = {
  actions: PropTypes.array,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imgUrl: PropTypes.string,
  title: PropTypes.string
}
export default Card
