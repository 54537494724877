import { useCallback, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Form, Modal } from 'antd'
import ProductForm from 'src/components/ProductForm'

const EditProductModal = props => {
  const { intl, isVisible, initialValues, onSubmit, onClose } = props
  const [formValues, setFormValues] = useState(initialValues)
  const isSubmitDisabled =
    !formValues.quartile ||
    formValues.isLocal === undefined ||
    formValues.descriptions.includes(undefined)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      setFormValues(initialValues)
      form.setFieldsValue(initialValues)
    }
    if (!isVisible) form.resetFields()
  }, [isVisible, initialValues, form])

  const onValuesChangeCallback = useCallback(
    (_, allValues) =>
      setFormValues(prevValues => ({ ...prevValues, ...allValues })),
    []
  )

  return (
    <Modal
      visible={isVisible}
      title={intl.formatMessage(
        { id: 'product.editModal.title' },
        { product: initialValues.text }
      )}
      okText={intl.formatMessage({ id: 'product.editModal.okText' })}
      cancelText={intl.formatMessage({ id: 'product.editModal.cancelText' })}
      okButtonProps={{ disabled: isSubmitDisabled }}
      onOk={() => onSubmit(formValues)}
      onCancel={onClose}>
      <ProductForm
        form={form}
        initialValues={initialValues}
        required={true}
        onValuesChange={onValuesChangeCallback}
      />
    </Modal>
  )
}
export default injectIntl(EditProductModal)
