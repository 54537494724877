import axios from 'axios'
import config from './config'
import { BU } from './constants'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': 'AFFA9291-15D7-4866-B25A-43BE3AF875B9'
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  department: {
    list: () => BE_API.get('/departments', { params: { bu: BU } })
  },
  salesPlan: {
    list: departmentId =>
      BE_API.get('/sales-plans', { params: { bu: BU, departmentId } }),
    get: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}`, { params: { bu: BU } }),
    create: body => BE_API.post('/sales-plans', body, { params: { bu: BU } }),
    update: (body, salesPlanId) =>
      BE_API.put(`/sales-plans/${salesPlanId}`, body, { params: { bu: BU } }),
    updateStatus: (body, salesPlanId) =>
      BE_API.put(`/sales-plans/${salesPlanId}/status`, body, {
        params: { bu: BU }
      }),
    delete: salesPlanId =>
      BE_API.delete(`/sales-plans/${salesPlanId}`, { params: { bu: BU } }),
    basicInfo: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/basic-info`, {
        params: { bu: BU }
      })
  },
  question: {
    list: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/questions`, {
        params: { bu: BU }
      }),
    upsert: body => BE_API.post('/questions', body, { params: { bu: BU } }),
    upsertMultiple: body =>
      BE_API.put('/questions', body, { params: { bu: BU } }),
    delete: questionId =>
      BE_API.delete(`/questions/${questionId}`, { params: { bu: BU } })
  },
  answer: {
    delete: answerId =>
      BE_API.delete(`/answers/${answerId}`, { params: { bu: BU } })
  },
  flow: {
    questionsFlow: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/questions-flow`, {
        params: { bu: BU }
      }),
    upsertLevel: body => BE_API.post('/flows', body, { params: { bu: BU } }),
    upsertMultipleLevels: body =>
      BE_API.put('/flows', body, { params: { bu: BU } }),
    deleteLevel: body =>
      BE_API.delete('/flows', { params: { bu: BU }, data: body }),
    userFlows: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/user-flows`, {
        params: { bu: BU }
      })
  },
  product: {
    list: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/products`, {
        params: { bu: BU }
      }),
    import: (body, salesPlanId) =>
      BE_API.post('/products', body, { params: { bu: BU, salesPlanId } }),
    update: (body, productId) =>
      BE_API.put(`/products/${productId}`, body, { params: { bu: BU } }),
    updateMultiple: body =>
      BE_API.put('/products', body, { params: { bu: BU } }),
    delete: productId =>
      BE_API.delete(`/products/${productId}`, { params: { bu: BU } }),
    deleteMultiple: body =>
      BE_API.delete('/products', { params: { bu: BU }, data: body })
  },
  productsTags: {
    upsert: body => BE_API.post('/products-tags', body, { params: { bu: BU } }),
    upsertMultiple: body =>
      BE_API.put('/products-tags', body, { params: { bu: BU } })
  },
  relatedProduct: {
    list: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/related-products`, {
        params: { bu: BU }
      }),
    import: (body, salesPlanId) =>
      BE_API.post('/related-products', body, {
        params: { bu: BU, salesPlanId }
      }),
    update: (body, productId) =>
      BE_API.put(`/related-products/${productId}`, body, {
        params: { bu: BU }
      }),
    updateMultiple: body =>
      BE_API.put('/related-products', body, { params: { bu: BU } }),
    delete: productId =>
      BE_API.delete(`/related-products/${productId}`, { params: { bu: BU } }),
    deleteMultiple: body =>
      BE_API.delete(`/related-products`, { params: { bu: BU }, data: body })
  }
}
export default apis
