import {
  DELETE_MULTIPLE_PRODUCTS,
  DELETE_PRODUCT,
  GET_PRODUCTS,
  IMPORT_PRODUCTS,
  UPDATE_MULTIPLE_PRODUCTS,
  UPDATE_PRODUCT
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getProducts = salesPlanId => async dispatch => {
  try {
    const res = await api.product.list(salesPlanId)
    dispatch({ type: GET_PRODUCTS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const importProducts = (body, salesPlanId) => async dispatch => {
  try {
    const res = await api.product.import(body, salesPlanId)
    dispatch({ type: IMPORT_PRODUCTS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateProduct = (body, productId) => async dispatch => {
  try {
    await api.product.update(body, productId)
    return dispatch({ type: UPDATE_PRODUCT })
  } catch (error) {
    return handleServerError(error)
  }
}
export const updateMultipleProducts = body => async dispatch => {
  try {
    const res = await api.product.updateMultiple(body)
    dispatch({ type: UPDATE_MULTIPLE_PRODUCTS })
    return { count: res.data.count }
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteProduct = productId => async dispatch => {
  try {
    const res = await api.product.delete(productId)
    dispatch({ type: DELETE_PRODUCT })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteMultipleProducts = body => async dispatch => {
  try {
    const res = await api.product.deleteMultiple(body)
    dispatch({ type: DELETE_MULTIPLE_PRODUCTS })
    return { status: res.status, count: res.data.count }
  } catch (error) {
    return handleServerError(error)
  }
}
