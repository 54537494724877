import styled from 'styled-components'
import { Button } from 'antd'
import { colors } from 'src/common/constants'

export const StyledIconButton = styled(Button)`
  height: min-content;
  &:not(.ant-btn-text[disabled]) {
    color: ${colors.grey4};
  }
`
