import {
  DELETE_SALES_PLAN,
  GET_BASIC_INFO,
  GET_SALES_PLAN,
  UPDATE_SALES_PLAN_STATUS,
  UPSERT_SALES_PLAN
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getSalesPlan = salesPlanId => async dispatch => {
  try {
    const res = await api.salesPlan.get(salesPlanId)
    dispatch({ type: GET_SALES_PLAN })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertSalesPlan = (body, salesPlanId) => async dispatch => {
  try {
    const res = salesPlanId
      ? await api.salesPlan.update(body, salesPlanId)
      : await api.salesPlan.create(body)
    dispatch({ type: UPSERT_SALES_PLAN })

    const { id, completedStep } = res.data
    return { id, completedStep }
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateSalesPlanStatus = (body, salesPlanId) => async dispatch => {
  try {
    await api.salesPlan.updateStatus(body, salesPlanId)
    return dispatch({ type: UPDATE_SALES_PLAN_STATUS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteSalesPlan = salesPlanId => async dispatch => {
  try {
    await api.salesPlan.delete(salesPlanId)
    return dispatch({ type: DELETE_SALES_PLAN })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getBasicInfo = salesPlanId => async dispatch => {
  try {
    const res = await api.salesPlan.basicInfo(salesPlanId)
    dispatch({ type: GET_BASIC_INFO })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}
