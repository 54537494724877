import { useCallback, useState } from 'react'
import { pick } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Alert, Empty, Input, List, Modal, Space } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import ProductCard from 'src/components/ProductCard'
import ImportProductsModal from 'src/components/ImportProductsModal'
import EditProductModal from 'src/components/EditProductModal'
import { ActionsRow } from 'src/common/styled'

import { EDIT_MODE_MULTI, EDIT_MODE_SINGLE } from 'src/common/constants'

const initialProductFormValues = {
  quartile: undefined,
  isLocal: 0,
  descriptions: [undefined]
}

const EmptyComponent = injectIntl(({ intl }) => (
  <Empty description={intl.formatMessage({ id: 'product.empty.text' })} />
))

const ProductsStep = props => {
  const {
    intl,
    products,
    notFoundProducts,
    hasAssociation,
    importProducts,
    deleteProduct,
    deleteMultipleProducts,
    updateProduct,
    updateMultipleProducts,
    onNext
  } = props

  const [editMode, setEditMode] = useState(EDIT_MODE_SINGLE)

  const [filteredProducts, setFilteredProducts] = useState(undefined)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)

  const [productFormValues, setProductFormValues] = useState(
    initialProductFormValues
  )

  const [selectedProducts, setSelectedProducts] = useState([])

  const isMultiActionDisabled = selectedProducts.length === 0
  const isNextDisabled =
    products.length === 0 || products.some(p => !p.isCompleted)

  const filterProducts = useCallback(
    e => {
      const value = e.target.value.toLowerCase()
      const filtered = products.filter(
        p =>
          p.text.toLowerCase().includes(value) ||
          p.id.toString().includes(value)
      )

      setFilteredProducts(filtered)
    },
    [products]
  )

  const openImportModal = useCallback(() => setIsImportModalVisible(true), [])
  const closeImportModal = useCallback(() => setIsImportModalVisible(false), [])

  const openEditModal = useCallback((product, editMode) => {
    setProductFormValues(prevValues => ({
      ...prevValues,
      ...pick(['id', 'text', 'quartile', 'isLocal', 'descriptions'], product)
    }))
    if (editMode) setEditMode(editMode)
    setIsEditModalVisible(true)
  }, [])

  const closeEditModal = useCallback(() => {
    setIsEditModalVisible(false)
    setProductFormValues(initialProductFormValues)
    setEditMode(EDIT_MODE_SINGLE)
  }, [])

  const updateSelectedProducts = useCallback((productId, checked) => {
    if (checked) {
      setSelectedProducts(prevProducts =>
        !prevProducts.includes(productId)
          ? [...prevProducts, productId]
          : prevProducts
      )
    } else {
      setSelectedProducts(prevProducts =>
        prevProducts.filter(pId => pId !== productId)
      )
    }
  }, [])

  const updateProductCallback = useCallback(
    formValues => {
      if (editMode === EDIT_MODE_SINGLE) return updateProduct(formValues)
      if (editMode === EDIT_MODE_MULTI)
        return updateMultipleProducts(selectedProducts, formValues)
    },
    [editMode, selectedProducts, updateProduct, updateMultipleProducts]
  )

  const deleteProductCallback = useCallback(
    productId => {
      return Modal.confirm({
        title: intl.formatMessage(
          { id: 'product.confirm.delete.title' },
          { productId }
        ),
        content: intl.formatMessage(
          {
            id: `product.confirm.delete.content${
              hasAssociation ? '.withAssociation' : ''
            }`
          },
          { productId }
        ),
        okText: intl.formatMessage({
          id: 'product.confirm.delete.okText'
        }),
        cancelText: intl.formatMessage({
          id: 'product.confirm.delete.cancelText'
        }),
        onOk: () => deleteProduct(productId)
      })
    },
    [intl, hasAssociation, deleteProduct]
  )

  const deleteMultipleProductsCallback = useCallback(() => {
    const count = selectedProducts.length
    return Modal.confirm({
      title: intl.formatMessage(
        { id: 'product.confirm.deleteMultiple.title' },
        { count }
      ),
      content: intl.formatMessage({
        id: `product.confirm.deleteMultiple.content${
          hasAssociation ? '.withAssociation' : ''
        }`
      }),
      okText: intl.formatMessage({
        id: 'product.confirm.deleteMultiple.okText'
      }),
      cancelText: intl.formatMessage({
        id: 'product.confirm.deleteMultiple.cancelText'
      }),
      onOk: () => deleteMultipleProducts(selectedProducts)
    })
  }, [intl, selectedProducts, hasAssociation, deleteMultipleProducts])

  const showTotal = useCallback(
    total => <FormattedMessage id={'product.totalItems'} values={{ total }} />,
    []
  )

  return (
    <>
      <ActionsRow>
        <Input.Search
          style={{ width: 320 }}
          placeholder={intl.formatMessage({
            id: 'product.placeholder.search'
          })}
          onChange={filterProducts}
        />
        <Space>
          <Button
            type={'text'}
            icon={<DeleteOutlined />}
            disabled={isMultiActionDisabled}
            onClick={deleteMultipleProductsCallback}>
            <FormattedMessage id={'product.button.deleteSelected'} />
          </Button>
          <Button
            type={'text'}
            icon={<EditOutlined />}
            disabled={isMultiActionDisabled}
            onClick={p => openEditModal(p, EDIT_MODE_MULTI)}>
            <FormattedMessage id={'product.button.editSelected'} />
          </Button>
          <Button onClick={openImportModal}>
            <FormattedMessage id={'product.button.import'} />
          </Button>
        </Space>
      </ActionsRow>
      {notFoundProducts.length > 0 ? (
        <Alert
          message={intl.formatMessage(
            { id: 'error.notFoundProducts' },
            { products: notFoundProducts.join(', ') }
          )}
          type={'error'}
          banner
          showIcon
          closable
        />
      ) : null}
      {products.length > 0 ? (
        <List
          grid={{ gutter: 16, column: 6 }}
          dataSource={filteredProducts || products}
          pagination={{ defaultPageSize: 18, showTotal }}
          locale={{
            emptyText: <EmptyComponent />
          }}
          renderItem={p => (
            <List.Item>
              <ProductCard
                product={p}
                onEdit={openEditModal}
                onDelete={deleteProductCallback}
                onSelectChange={updateSelectedProducts}
              />
            </List.Item>
          )}
        />
      ) : (
        <EmptyComponent />
      )}
      <EditProductModal
        isVisible={isEditModalVisible}
        initialValues={productFormValues}
        onSubmit={updateProductCallback}
        onClose={closeEditModal}
      />
      <ImportProductsModal
        isVisible={isImportModalVisible}
        initialValues={productFormValues}
        onSubmit={importProducts}
        onClose={closeImportModal}
      />
      <ActionsRow justify={'end'}>
        <Button disabled={isNextDisabled} onClick={onNext}>
          <FormattedMessage
            id={'salesPlan.button.goToStepProductsAssociation'}
          />
        </Button>
      </ActionsRow>
    </>
  )
}
export default injectIntl(ProductsStep)
