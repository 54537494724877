import { useCallback } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Card, Col, Row } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import QuestionsFlowQuestion from '../QuestionsFlowQuestion'

import { colors } from 'src/common/constants'

const QuestionsFlowLevel = props => {
  const { intl, qfLevel, questions, isDeletable, onUpdate, onDelete } = props
  const headStyle = { backgroundColor: colors.main, color: colors.white }

  const onQuestionChange = useCallback(
    (questionId, questionIndex) => {
      const newQuestions = [...qfLevel.questions]
      newQuestions[questionIndex].id = questionId
      newQuestions[questionIndex].answers = newQuestions[
        questionIndex
      ].answers.map(_ => [])

      const newLevel = { ...qfLevel, questions: newQuestions }

      onUpdate(newLevel)
    },
    [qfLevel, onUpdate]
  )

  const onAnswerChange = useCallback(
    (answerIds, tabIndex, questionIndex) => {
      const newQuestions = [...qfLevel.questions]
      newQuestions[questionIndex].answers[tabIndex] = answerIds

      const newLevel = { ...qfLevel, questions: newQuestions }
      onUpdate(newLevel)
    },
    [qfLevel, onUpdate]
  )

  const addTab = useCallback(
    questionIndex => {
      const newQuestions = [...qfLevel.questions]
      newQuestions[questionIndex].answers.push([])

      const newLevel = { ...qfLevel, questions: newQuestions }
      onUpdate(newLevel)
    },
    [qfLevel, onUpdate]
  )

  const removeTab = useCallback(
    (tabIndex, questionIndex) => {
      const newQuestions = [...qfLevel.questions]
      newQuestions[questionIndex].answers.splice(tabIndex, 1)

      const newLevel = { ...qfLevel, questions: newQuestions }
      onUpdate(newLevel)
    },
    [qfLevel, onUpdate]
  )

  return (
    <Card
      size={'small'}
      title={intl.formatMessage(
        { id: 'questionsFlow.card.title' },
        { index: qfLevel.level }
      )}
      extra={
        // TODO: Modal.confirm with alert
        isDeletable ? (
          <Button
            icon={<DeleteOutlined />}
            size={'small'}
            noShadow
            onClick={onDelete}>
            <FormattedMessage id={'questionsFlow.button.deleteLevel'} />
          </Button>
        ) : null
      }
      headStyle={headStyle}>
      <h3>
        <FormattedMessage id={'questionsFlow.level.title'} />
      </h3>
      <p>
        <FormattedMessage
          id={'questionsFlow.level.text'}
          values={{ required: <span style={{ color: colors.error }}>*</span> }}
        />
      </p>
      <Row gutter={[16, 16]}>
        {qfLevel.questions.map((q, qIndex) => (
          <Col span={12} key={qIndex}>
            <QuestionsFlowQuestion
              question={q}
              questions={questions}
              onQuestionChange={qId => onQuestionChange(qId, qIndex)}
              onAnswerChange={(aId, tabIndex) =>
                onAnswerChange(aId, tabIndex, qIndex)
              }
              addTab={() => addTab(qIndex)}
              removeTab={tabIndex => removeTab(tabIndex, qIndex)}
            />
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default injectIntl(QuestionsFlowLevel)
