import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage } from 'react-intl'

import User from '../User'
import { AppTitle, HeaderBar, StyledHeader } from './styled'
import logo from '../../assets/img/logo.png'

const Header = props => {
  const { user } = props
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <img src={logo} alt={'logo'} height={32} />
        <AppTitle>
          <FormattedMessage id={'app.title'} />
        </AppTitle>
        {!isEmpty(user) ? <User user={user} /> : null}
      </StyledHeader>
    </>
  )
}
Header.propTypes = {
  user: PropTypes.object
}
export default Header
