// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_SALES_PLANS = 'GET_SALES_PLANS'
export const GET_SALES_PLAN = 'GET_SALES_PLAN'
export const UPSERT_SALES_PLAN = 'UPSERT_SALES_PLAN'
export const UPDATE_SALES_PLAN_STATUS = 'UPDATE_SALES_PLAN_STATUS'
export const DELETE_SALES_PLAN = 'DELETE_SALES_PLAN'
export const GET_BASIC_INFO = 'GET_BASIC_INFO'

export const GET_QUESTIONS = 'GET_QUESTIONS'
export const UPSERT_MULTIPLE_QUESTIONS = 'UPSERT_MULTIPLE_QUESTIONS'
export const UPSERT_QUESTION = 'UPSERT_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'

export const DELETE_ANSWER = 'DELETE_ANSWER'

export const GET_QUESTIONS_FLOW = 'GET_QUESTIONS_FLOW'
export const UPSERT_MULTIPLE_FLOW_LEVELS = 'UPSERT_MULTIPLE_FLOW_LEVELS'
export const UPSERT_FLOW_LEVEL = 'UPSERT_FLOW_LEVEL'
export const DELETE_FLOW_LEVEL = 'DELETE_FLOW_LEVEL'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_MULTIPLE_PRODUCTS = 'UPDATE_MULTIPLE_PRODUCTS'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_MULTIPLE_PRODUCTS = 'DELETE_MULTIPLE_PRODUCTS'

export const GET_USER_FLOWS = 'GET_USER_FLOWS'
export const UPSERT_PRODUCTS_TAGS = 'UPSERT_PRODUCTS_TAGS'
export const UPSERT_MULTIPLE_PRODUCTS_TAGS = 'UPSERT_MULTIPLE_PRODUCTS_TAGS'

export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS'
export const IMPORT_RELATED_PRODUCTS = 'IMPORT_RELATED_PRODUCTS'
export const UPDATE_RELATED_PRODUCT = 'UPDATE_RELATED_PRODUCT'
export const UPDATE_MULTIPLE_RELATED_PRODUCTS =
  'UPDATE_MULTIPLE_RELATED_PRODUCTS'
export const DELETE_RELATED_PRODUCT = 'DELETE_RELATED_PRODUCT'
export const DELETE_MULTIPLE_RELATED_PRODUCTS =
  'DELETE_MULTIPLE_RELATED_PRODUCTS'
