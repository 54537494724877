import { useState, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { getDepartments } from 'src/redux-store/actions/app-actions'

import Loader from 'src/components/Loader'
import { PageHeader, PageTitle } from 'src/common/styled'
import RadioGrid from 'src/components/RadioGrid'

const Home = props => {
  const { history, departments, getDepartmentsAction } = props
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function getDepartments() {
      setIsLoading(true)
      await getDepartmentsAction()
      setIsLoading(false)
    }

    getDepartments()
  }, [getDepartmentsAction])

  const viewSalesPlans = useCallback(
    departmentId => {
      history.push(`/sales-plans?departmentId=${departmentId}`)
    },
    [history]
  )

  if (isLoading) return <Loader />
  return (
    <>
      <PageHeader>
        <PageTitle>
          <FormattedMessage id={'home.title'} />
        </PageTitle>
      </PageHeader>
      <RadioGrid
        items={departments}
        columns={{ xs: 2, xl: 5 }}
        imgHeight={30}
        onChange={viewSalesPlans}
      />
    </>
  )
}
const mapStateToProps = state => ({
  departments: state.app.departments
})
const mapDispatchToProps = {
  getDepartmentsAction: getDepartments
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
