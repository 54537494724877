import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'
import {
  UPSERT_MULTIPLE_PRODUCTS_TAGS,
  UPSERT_PRODUCTS_TAGS
} from '../action-types'

export const upsertProductsTags = body => async dispatch => {
  try {
    await api.productsTags.upsert(body)
    return dispatch({ type: UPSERT_PRODUCTS_TAGS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertMultipleProductsTags = body => async dispatch => {
  try {
    await api.productsTags.upsertMultiple(body)
    return dispatch({ type: UPSERT_MULTIPLE_PRODUCTS_TAGS })
  } catch (error) {
    return handleServerError(error)
  }
}
