import styled from 'styled-components'
import { Collapse } from 'antd'
import { colors } from 'src/common/constants'

export const StyledPanel = styled(Collapse.Panel)`
  border: none !important;
  > .ant-collapse-header {
    border: 2px solid ${colors.main};
    border-radius: 2px !important;
    padding: 8px !important;
    font-weight: 600;
    color: ${props =>
      props.isCompleted
        ? `${colors.white} !important`
        : `${colors.main} !important`};
    background-color: ${props =>
      props.isCompleted
        ? `${colors.main} !important`
        : `${colors.white} !important`};
  }
  > .ant-collapse-content {
    border: 1px solid ${colors.grey2};
    border-top: none;
  }
`

export const ItemsList = styled.ol`
  margin-bottom: 0;
  padding-inline-start: 20px;
`
export const ItemsListItem = styled.li`
  color: ${colors.main};
  font-weight: 500;
  > p {
    color: ${colors.grey4};
    font-weight: 300;
  }
`
