import { useCallback, useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { message, Modal, Space } from 'antd'
import { connect } from 'react-redux'

import {
  upsertSalesPlan,
  getSalesPlan,
  getBasicInfo
} from 'src/redux-store/actions/salesPlan-actions'
import {
  getQuestions,
  upsertMultipleQuestions,
  upsertQuestion,
  deleteQuestion,
  deleteAnswer
} from 'src/redux-store/actions/question-actions'
import {
  getQuestionsFlow,
  upsertMultipleFlowLevels,
  upsertFlowLevel,
  deleteFlowLevel,
  getUserFlows
} from 'src/redux-store/actions/flow-actions'
import {
  getProducts,
  importProducts,
  updateProduct,
  updateMultipleProducts,
  deleteProduct,
  deleteMultipleProducts
} from 'src/redux-store/actions/product-actions'
import {
  upsertProductsTags,
  upsertMultipleProductsTags
} from 'src/redux-store/actions/productsTags-actions'
import {
  getRelatedProducts,
  importRelatedProducts,
  updateRelatedProduct,
  updateMultipleRelatedProducts,
  deleteRelatedProduct,
  deleteMultipleRelatedProducts
} from 'src/redux-store/actions/relatedProduct-actions'

import Loader from 'src/components/Loader'
import Button from 'src/components/Button'
import Breadcrumb from 'src/components/Breadcrumb'
import Stepper from 'src/components/Stepper'
import { ActionsRow, PageHeader, PageTitle } from 'src/common/styled'

import BasicInfoStep from './BasicInfoStep'
import QuestionsStep from './QuestionsStep'
import QuestionsFlowStep from './QuestionsFlowStep'
import ProductsStep from './ProductsStep'
import ProductsAssociationStep from './ProductsAssociationStep'
import RelatedProductsStep from './RelatedProductsStep'

import {
  SALES_PLAN_STATUS_COMPLETED,
  SALES_PLAN_STATUS_DRAFT,
  SALES_PLAN_STEP_BASIC_INFO,
  SALES_PLAN_STEP_PRODUCTS,
  SALES_PLAN_STEP_PRODUCTS_ASSOCIATION,
  SALES_PLAN_STEP_QUESTIONS,
  SALES_PLAN_STEP_QUESTIONS_FLOW,
  SALES_PLAN_STEP_RELATED_PRODUCTS
} from 'src/common/constants'

const initialBasicFormValues = {
  name: undefined,
  benefits: [undefined],
  preSaleAdvice: [undefined],
  postSaleAdvice: [undefined],
  q1Features: [undefined],
  q2Features: [undefined],
  q3Features: [undefined],
  q4Features: [undefined]
}

const initialQuestionFormValues = {
  text: undefined,
  answers: [undefined]
}

const initialQuestionFlowLevel = {
  level: 1,
  questions: [{ answers: [[]] }]
}

const SalesPlan = props => {
  const {
    history,
    location,
    match,
    intl,
    departments,
    upsertSalesPlan,
    getSalesPlanAction,
    getBasicInfoAction,
    getQuestionsAction,
    upsertMultipleQuestionsAction,
    upsertQuestion,
    deleteQuestionAction,
    deleteAnswerAction,
    getQuestionsFlowAction,
    upsertMultipleFlowLevelsAction,
    upsertFlowLevel,
    deleteFlowLevel,
    getUserFlowsAction,
    getProductsAction,
    importProductsAction,
    updateProductAction,
    updateMultipleProductsAction,
    deleteProductAction,
    deleteMultipleProductsAction,
    upsertProductsTagsAction,
    upsertMultipleProductsTagsAction,
    getRelatedProductsAction,
    importRelatedProductsAction,
    updateRelatedProductAction,
    updateMultipleRelatedProductsAction,
    deleteRelatedProductAction,
    deleteMultipleRelatedProductsAction
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(
    location.state?.completedStep || SALES_PLAN_STEP_BASIC_INFO
  )
  const [needsCloseConfirm, setNeedsCloseConfirm] = useState(false)

  const [basicInfoFormValues, setBasicInfoFormValues] = useState(
    initialBasicFormValues
  )

  const [departmentId /*setDepartmentId*/] = useState(
    location.state?.departmentId
  )
  const [currentDepartment] = useState(
    departments.find(d => d.id === departmentId) || {}
  )
  const [salesPlanId, setSalesPlanId] = useState(match.params.salesPlanId)
  const [salesPlanStatus, setSalesPlanStatus] = useState(undefined)
  const [completedStep, setCompletedStep] = useState(0)

  const [questions, setQuestions] = useState([initialQuestionFormValues])

  const [questionsFlow, setQuestionsFlow] = useState([initialQuestionFlowLevel])

  const [products, setProducts] = useState([])
  const [notFoundProducts, setNotFoundProducts] = useState([])

  const [userFlows, setUserFlows] = useState([])

  const [relatedProducts, setRelatedProducts] = useState([])
  const [notFoundRelatedProducts, setNotFoundRelatedProducts] = useState([])

  const pageMode = location.pathname.includes('new') ? 'new' : 'edit'

  const hasFlow = completedStep >= SALES_PLAN_STEP_QUESTIONS_FLOW
  const hasAssociation = completedStep >= SALES_PLAN_STEP_PRODUCTS_ASSOCIATION

  const isSaveDraftDisabled = useCallback(() => {
    switch (currentStep) {
      case SALES_PLAN_STEP_BASIC_INFO:
        return !basicInfoFormValues.name
      default:
        return false
    }
  }, [currentStep, basicInfoFormValues])

  const getSalesPlan = useCallback(
    async salesPlanId => {
      setIsLoading(true)
      const salesPlan = await getSalesPlanAction(salesPlanId)

      setBasicInfoFormValues(prevValues => ({
        ...prevValues,
        name: salesPlan.name
      }))
      setSalesPlanStatus(salesPlan.status)
      setCompletedStep(salesPlan.completedStep)
      // setIsLoading(false)
    },
    [getSalesPlanAction]
  )

  const getBasicInfo = useCallback(
    async salesPlanId => {
      setIsLoading(true)
      const basicInfo = await getBasicInfoAction(salesPlanId)
      setBasicInfoFormValues(prevValues => ({ ...prevValues, ...basicInfo }))
      setIsLoading(false)
    },
    [getBasicInfoAction]
  )

  const getQuestions = useCallback(
    async action => {
      setIsLoading(true)
      const questions = await getQuestionsAction(salesPlanId)
      if (questions.length > 0) {
        setQuestions(
          action === 'add'
            ? [...questions, initialQuestionFormValues]
            : questions
        )
      }
      setIsLoading(false)
    },
    [salesPlanId, getQuestionsAction]
  )

  const getQuestionsFlow = useCallback(async () => {
    setIsLoading(true)
    const questionsFlow = await getQuestionsFlowAction(salesPlanId)
    if (questionsFlow.length > 0) {
      setQuestionsFlow(questionsFlow)
    }

    setIsLoading(false)
  }, [salesPlanId, getQuestionsFlowAction])

  const getProducts = useCallback(async () => {
    setIsLoading(true)
    const products = await getProductsAction(salesPlanId)
    setProducts(products)
    setIsLoading(false)
  }, [salesPlanId, getProductsAction])

  const getUserFlows = useCallback(async () => {
    setIsLoading(true)
    const userFlows = await getUserFlowsAction(salesPlanId)
    setUserFlows(userFlows)
    setIsLoading(false)
  }, [salesPlanId, getUserFlowsAction])

  const getRelatedProducts = useCallback(async () => {
    setIsLoading(true)
    const relatedProducts = await getRelatedProductsAction(salesPlanId)
    setRelatedProducts(relatedProducts)
    setIsLoading(false)
  }, [salesPlanId, getRelatedProductsAction])

  useEffect(() => {
    if (!departmentId) {
      // senza setTimeOut, history.push non viene eseguito
      setTimeout(() => history.push('/'), 0)
    }
    if (salesPlanId /*&& currentStep === SALES_PLAN_STEP_BASIC_INFO*/) {
      getSalesPlan(salesPlanId)
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_BASIC_INFO) {
      getBasicInfo(salesPlanId)
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_QUESTIONS) {
      getQuestions()
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_QUESTIONS_FLOW) {
      getQuestionsFlow()
      getQuestions()
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_PRODUCTS) {
      getProducts()
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_PRODUCTS_ASSOCIATION) {
      getUserFlows()
      getProducts()
    }
    if (salesPlanId && currentStep === SALES_PLAN_STEP_RELATED_PRODUCTS) {
      getRelatedProducts()
      getProducts()
    }
  }, [
    history,
    currentStep,
    departmentId,
    salesPlanId,
    getSalesPlan,
    getBasicInfo,
    getQuestions,
    getQuestionsFlow,
    getProducts,
    getUserFlows,
    getRelatedProducts
  ])

  const saveDraft = useCallback(
    async step => {
      const body = {
        departmentId,
        status: salesPlanStatus || SALES_PLAN_STATUS_DRAFT,
        completedStep: completedStep > step ? completedStep : step
      }

      setIsLoading(true)
      const spData = await upsertSalesPlan(body, salesPlanId)
      setCompletedStep(spData.completedStep)
      setIsLoading(false)
    },
    [departmentId, salesPlanId, salesPlanStatus, completedStep, upsertSalesPlan]
  )

  const goToSalesPlans = useCallback(() => {
    return history.push(`/sales-plans?departmentId=${departmentId}`)
  }, [history, departmentId])

  const goToStepQuestions = useCallback(
    () => setCurrentStep(SALES_PLAN_STEP_QUESTIONS),
    []
  )
  const goToStepQuestionsFlow = useCallback(
    () => setCurrentStep(SALES_PLAN_STEP_QUESTIONS_FLOW),
    []
  )
  const goToStepProducts = useCallback(
    () => setCurrentStep(SALES_PLAN_STEP_PRODUCTS),
    []
  )
  const goToStepProductsAssociation = useCallback(
    () => setCurrentStep(SALES_PLAN_STEP_PRODUCTS_ASSOCIATION),
    []
  )
  const goToStepRelatedProducts = useCallback(
    () => setCurrentStep(SALES_PLAN_STEP_RELATED_PRODUCTS),
    []
  )

  const onStepChange = useCallback(
    step => {
      if (!salesPlanId || (salesPlanId && completedStep < step)) {
        return
      }
      setNotFoundProducts([])
      setNotFoundRelatedProducts([])
      setCurrentStep(step)
    },
    [salesPlanId, completedStep]
  )

  const renderSuccessMessage = useCallback(
    (messageId, values) => {
      return message.success(intl.formatMessage({ id: messageId }, values), 5)
    },
    [intl]
  )

  const closeSalesPlanCallback = useCallback(() => {
    if (needsCloseConfirm) {
      return Modal.confirm({
        title: intl.formatMessage({ id: 'salesPlan.confirm.close.title' }),
        content: intl.formatMessage({
          id: 'salesPlan.confirm.close.content'
        }),
        okText: intl.formatMessage({ id: 'salesPlan.confirm.close.okText' }),
        cancelText: intl.formatMessage({
          id: 'salesPlan.confirm.close.cancelText'
        }),
        onOk: goToSalesPlans
      })
    }

    goToSalesPlans()
  }, [intl, needsCloseConfirm, goToSalesPlans])

  const onBasicInfoFormValuesChange = useCallback(formValues => {
    setNeedsCloseConfirm(true)
    setBasicInfoFormValues(formValues)
  }, [])

  const submitBasicInfoForm = useCallback(async () => {
    const body = {
      departmentId,
      ...basicInfoFormValues,
      status: salesPlanStatus || SALES_PLAN_STATUS_DRAFT,
      completedStep:
        completedStep > SALES_PLAN_STEP_BASIC_INFO
          ? completedStep
          : SALES_PLAN_STEP_BASIC_INFO
    }

    setIsLoading(true)
    const spData = await upsertSalesPlan(body, salesPlanId)
    setSalesPlanId(spData.id)
    setCompletedStep(spData.completedStep)
    setIsLoading(false)
  }, [
    departmentId,
    salesPlanId,
    salesPlanStatus,
    completedStep,
    basicInfoFormValues,
    upsertSalesPlan
  ])

  const onBasicInfoStepNext = useCallback(async () => {
    await submitBasicInfoForm()
    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.onNext', {
      step: intl.formatMessage({ id: 'stepper.step.basicInfo' })
    })
    goToStepQuestions()
  }, [intl, submitBasicInfoForm, renderSuccessMessage, goToStepQuestions])

  const addQuestion = useCallback(async () => {
    const lastQuestion = questions[questions.length - 1]
    if (lastQuestion.id) {
      setQuestions(prevQuestions => [
        ...prevQuestions,
        initialQuestionFormValues
      ])
    } else {
      const body = { salesPlanId, ...lastQuestion }
      setIsLoading(true)
      await upsertQuestion(body)

      getQuestions('add')
    }
  }, [questions, salesPlanId, upsertQuestion, getQuestions])

  const updateQuestion = useCallback((index, questionValues) => {
    setNeedsCloseConfirm(true)
    setQuestions(prevQuestions =>
      prevQuestions.map((q, i) => {
        if (i === index) return { ...q, ...questionValues }
        return q
      })
    )
  }, [])

  const upsertMultipleQuestions = useCallback(async () => {
    const lastQuestion = questions[questions.length - 1]
    const isLastQuestionEmpty =
      !lastQuestion.text || !lastQuestion.answers[0]?.text
    if (isLastQuestionEmpty) questions.pop()

    const body = { salesPlanId, questions }

    setIsLoading(true)
    await upsertMultipleQuestionsAction(body)
    getQuestions()
  }, [salesPlanId, questions, upsertMultipleQuestionsAction, getQuestions])

  const deleteQuestion = useCallback(
    async index => {
      const question = questions[index]
      let qRes, fRes

      if (question.id) {
        setIsLoading(true)
        qRes = await deleteQuestionAction(question.id)

        if (hasFlow) {
          const body = { salesPlanId, level: question.level }
          fRes = await deleteFlowLevel(body)
        }

        getQuestions()
        if (
          (hasFlow && fRes.status === 200 && qRes.status === 200) ||
          qRes.status === 200
        ) {
          renderSuccessMessage(
            `question.message.delete${hasAssociation ? '.withAssociation' : hasFlow ? '.withFlow' : ''}`,
            { index: index + 1 }
          )
        }
      } else {
        setQuestions(prevQuestions =>
          prevQuestions.filter((q, i) => i !== index)
        )
      }
    },
    [
      salesPlanId,
      hasFlow,
      hasAssociation,
      questions,
      deleteQuestionAction,
      deleteFlowLevel,
      getQuestions,
      renderSuccessMessage
    ]
  )

  const deleteAnswer = useCallback(
    async (answerId, questionLevel) => {
      let aRes, fRes

      setIsLoading(true)
      aRes = await deleteAnswerAction(answerId)

      if (hasFlow) {
        const body = { salesPlanId, level: questionLevel + 1 }
        fRes = await deleteFlowLevel(body)
      }

      getQuestions()
      if (
        (hasFlow && fRes.status === 200 && aRes.status === 200) ||
        aRes.status === 200
      ) {
        renderSuccessMessage(
          `answer.message.delete${hasAssociation ? '.withAssociation' : hasFlow ? '.withFlow' : ''}`
        )
      }
    },
    [
      salesPlanId,
      hasFlow,
      hasAssociation,
      deleteAnswerAction,
      deleteFlowLevel,
      getQuestions,
      renderSuccessMessage
    ]
  )

  const onQuestionsStepNext = useCallback(async () => {
    await upsertMultipleQuestions()
    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.onNext', {
      step: intl.formatMessage({ id: 'stepper.step.questions' })
    })
    goToStepQuestionsFlow()
  }, [
    intl,
    upsertMultipleQuestions,
    renderSuccessMessage,
    goToStepQuestionsFlow
  ])

  const addQuestionsFlowLevel = useCallback(async () => {
    const lastLevel = questionsFlow[questionsFlow.length - 1]
    let nextLevelType = ''

    const hasCases = lastLevel.questions.some(q => q.answers.length > 1)
    const lastLevelQuestionIds = [
      ...new Set(lastLevel.questions.map(q => q.id))
    ]
    const lastLevelAnswers = [
      ...new Set(lastLevel.questions.map(q => q.answers.toString()))
    ]

    if (hasCases) nextLevelType = 'forkStart'
    if (
      !hasCases &&
      (lastLevelQuestionIds.length > 1 ||
        (lastLevelQuestionIds.length === 1 && lastLevelAnswers.length > 1))
    )
      nextLevelType = 'forkContinue'
    if (
      !hasCases &&
      lastLevelQuestionIds.length === 1 &&
      lastLevelAnswers.length === 1
    )
      nextLevelType = 'forkEnd'

    const nextLevelQuestions = []

    switch (nextLevelType) {
      case 'forkStart':
      case 'forkContinue':
        lastLevel.questions.forEach(forkQ => {
          const forkRelatedQuestion = questions.find(
            question => question.id === forkQ.id
          )
          let forkRelatedAnswers = []

          forkQ.answers.forEach(ans => {
            forkRelatedAnswers = ans.map(a =>
              forkRelatedQuestion.answers.find(rqA => rqA.id === a)
            )
            nextLevelQuestions.push({
              relatedQuestion: forkRelatedQuestion,
              relatedAnswers: forkRelatedAnswers,
              answers: [[]]
            })
          })
        })

        break
      case 'forkEnd':
        const forkEndQ = lastLevel.questions[0]
        const forkEndRelatedQuestion = questions.find(
          question => question.id === forkEndQ.id
        )
        let forkEndRelatedAnswers = []

        forkEndQ.answers.forEach(ans => {
          forkEndRelatedAnswers = ans.map(a =>
            forkEndRelatedQuestion.answers.find(rqA => rqA.id === a)
          )
        })

        nextLevelQuestions.push({
          relatedQuestion: forkEndRelatedQuestion,
          relatedAnswers: forkEndRelatedAnswers,
          answers: [[]]
        })
        break
      default:
        break
    }

    setQuestionsFlow(prevQuestionsFlow => [
      ...prevQuestionsFlow,
      { level: lastLevel.level + 1, questions: nextLevelQuestions }
    ])

    const body = { salesPlanId, ...lastLevel }
    // setIsLoading(true)
    await upsertFlowLevel(body)
    // setIsLoading(false)
  }, [salesPlanId, questionsFlow, questions, upsertFlowLevel])

  const upsertMultipleFlowLevels = useCallback(async () => {
    const lastLevel = questionsFlow[questionsFlow.length - 1]
    const isLastLevelEmpty = lastLevel.questions.every(q => !q.id)
    if (isLastLevelEmpty) questionsFlow.pop()

    const flowLevelsBody = { salesPlanId, flowLevels: questionsFlow }

    setIsLoading(true)
    await upsertMultipleFlowLevelsAction(flowLevelsBody)
    getQuestionsFlow()
  }, [
    salesPlanId,
    questionsFlow,
    upsertMultipleFlowLevelsAction,
    getQuestionsFlow
  ])

  const deleteQuestionsFlowLevel = useCallback(
    async index => {
      setQuestionsFlow(prevQuestionsFlow => prevQuestionsFlow.slice(0, index))

      const level = questionsFlow.find((_, i) => i === index).level
      const body = { salesPlanId, level }
      const res = await deleteFlowLevel(body)

      if (res.status === 200) {
        renderSuccessMessage(
          `questionsFlow.message.delete${hasAssociation ? '.withAssociation' : ''}`
        )
      }
    },
    [
      salesPlanId,
      hasAssociation,
      questionsFlow,
      deleteFlowLevel,
      renderSuccessMessage
    ]
  )

  const updateQuestionsFlowLevel = useCallback(
    async (index, level) => {
      setNeedsCloseConfirm(true)
      setQuestionsFlow(prevQuestionsFlow =>
        prevQuestionsFlow.map((qfLevel, i) => {
          if (i === index) return { ...qfLevel, ...level }
          return qfLevel
        })
      )
      if (index < questionsFlow.length - 1) {
        await deleteQuestionsFlowLevel(index + 1)
      }
    },
    [questionsFlow, deleteQuestionsFlowLevel]
  )

  const onQuestionsFlowStepNext = useCallback(async () => {
    await upsertMultipleFlowLevels()
    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.onNext', {
      step: intl.formatMessage({ id: 'stepper.step.questionsFlow' })
    })
    goToStepProducts()
  }, [intl, upsertMultipleFlowLevels, renderSuccessMessage, goToStepProducts])

  const importProducts = useCallback(
    async (productIds, values) => {
      const body = { productIds, values }

      setIsLoading(true)
      const { products, notFoundProducts } = await importProductsAction(
        body,
        salesPlanId
      )

      setProducts(prevProducts =>
        [...prevProducts, ...products].sort(
          (a, b) => a.isCompleted - b.isCompleted
        )
      )
      setNotFoundProducts(notFoundProducts)
      setIsLoading(false)
    },
    [salesPlanId, importProductsAction]
  )

  const updateProduct = useCallback(
    async formValues => {
      const { id, quartile, isLocal, descriptions } = formValues
      const body = { quartile, isLocal, descriptions }

      setIsLoading(true)
      await updateProductAction(body, id)
      getProducts()
    },
    [updateProductAction, getProducts]
  )

  const updateMultipleProducts = useCallback(
    async (productIds, formValues) => {
      const { quartile, isLocal, descriptions } = formValues
      const body = { productIds, quartile, isLocal, descriptions }

      setIsLoading(true)
      const res = await updateMultipleProductsAction(body)
      getProducts()

      if (res.count)
        renderSuccessMessage('product.message.updateMultiple', {
          count: res.count
        })
    },
    [updateMultipleProductsAction, getProducts, renderSuccessMessage]
  )

  const deleteProduct = useCallback(
    async productId => {
      setIsLoading(true)
      const res = await deleteProductAction(productId)
      getProducts()

      if (res.status === 200) {
        renderSuccessMessage(
          `product.message.delete${hasAssociation ? '.withAssociation' : ''}`,
          { productId }
        )
      }
    },
    [hasAssociation, deleteProductAction, getProducts, renderSuccessMessage]
  )

  const deleteMultipleProducts = useCallback(
    async productIds => {
      const body = { productIds }

      setIsLoading(true)
      const res = await deleteMultipleProductsAction(body)
      getProducts()

      if (res.status === 200) {
        renderSuccessMessage(
          `product.message.deleteMultiple${hasAssociation ? '.withAssociation' : ''}`,
          {
            count: res.count
          }
        )
      }
    },
    [
      hasAssociation,
      deleteMultipleProductsAction,
      getProducts,
      renderSuccessMessage
    ]
  )

  const onProductsStepNext = useCallback(async () => {
    await saveDraft(SALES_PLAN_STEP_PRODUCTS)
    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.onNext', {
      step: intl.formatMessage({ id: 'stepper.step.products' })
    })
    goToStepProductsAssociation()
  }, [intl, saveDraft, renderSuccessMessage, goToStepProductsAssociation])

  const updateUserFlow = useCallback((index, userFlowValues) => {
    setNeedsCloseConfirm(true)
    setUserFlows(prevUserFlows =>
      prevUserFlows.map((uf, i) => {
        if (i === index) return { ...uf, ...userFlowValues }
        return uf
      })
    )
  }, [])

  const upsertProductsTags = useCallback(
    async index => {
      const { productIds, items } = userFlows[index]
      const body = {
        salesPlanId,
        productIds,
        answerIds: items.map(item => item.answerId)
      }
      setIsLoading(true)
      await upsertProductsTagsAction(body)
      getUserFlows()
    },
    [salesPlanId, userFlows, upsertProductsTagsAction, getUserFlows]
  )

  const upsertMultipleProductsTags = useCallback(async () => {
    const productsTags = userFlows.map(uf => ({
      productIds: uf.productIds,
      answerIds: uf.items.map(item => item.answerId)
    }))
    const productsTagsBody = { salesPlanId, productsTags }

    setIsLoading(true)
    await upsertMultipleProductsTagsAction(productsTagsBody)
    setIsLoading(false)
  }, [salesPlanId, userFlows, upsertMultipleProductsTagsAction])

  const onProductsAssociationStepNext = useCallback(async () => {
    await upsertMultipleProductsTags()
    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.onNext', {
      step: intl.formatMessage({ id: 'stepper.step.productsAssociation' })
    })
    goToStepRelatedProducts()
  }, [
    intl,
    upsertMultipleProductsTags,
    renderSuccessMessage,
    goToStepRelatedProducts
  ])

  const importRelatedProducts = useCallback(
    async (productIds, values) => {
      const body = { productIds, values }

      setIsLoading(true)
      const { products, notFoundProducts } = await importRelatedProductsAction(
        body,
        salesPlanId
      )

      setRelatedProducts(prevProducts =>
        [...prevProducts, ...products].sort(
          (a, b) => a.isCompleted - b.isCompleted
        )
      )
      setNotFoundRelatedProducts(notFoundProducts)
      setIsLoading(false)
    },
    [salesPlanId, importRelatedProductsAction]
  )

  const updateRelatedProduct = useCallback(
    async formValues => {
      const { id, type, productIds } = formValues
      const body = { salesPlanId, type, productIds: productIds.map(id => +id) }

      setIsLoading(true)
      await updateRelatedProductAction(body, id)
      getRelatedProducts()
    },
    [salesPlanId, updateRelatedProductAction, getRelatedProducts]
  )

  const updateMultipleRelatedProducts = useCallback(
    async (relatedProductIds, formValues) => {
      const { type, productIds } = formValues
      const body = {
        relatedProductIds,
        salesPlanId,
        type,
        productIds: productIds.map(id => +id)
      }

      setIsLoading(true)
      const res = await updateMultipleRelatedProductsAction(body)
      getRelatedProducts()

      if (res.status === 200) {
        renderSuccessMessage('relatedProduct.message.updateMultiple', {
          count: res.count
        })
      }
    },
    [
      salesPlanId,
      updateMultipleRelatedProductsAction,
      getRelatedProducts,
      renderSuccessMessage
    ]
  )

  const deleteRelatedProduct = useCallback(
    async productId => {
      setIsLoading(true)
      const res = await deleteRelatedProductAction(productId)
      getRelatedProducts()

      if (res.status === 200) {
        renderSuccessMessage('relatedProduct.message.delete', { productId })
      }
    },
    [deleteRelatedProductAction, getRelatedProducts, renderSuccessMessage]
  )

  const deleteMultipleRelatedProducts = useCallback(
    async productIds => {
      const body = { productIds }

      setIsLoading(true)
      const res = await deleteMultipleRelatedProductsAction(body)
      getRelatedProducts()

      if (res.status === 200) {
        renderSuccessMessage('relatedProduct.message.deleteMultiple', {
          count: res.count
        })
      }
    },
    [
      deleteMultipleRelatedProductsAction,
      getRelatedProducts,
      renderSuccessMessage
    ]
  )

  const saveCloseSalesPlan = useCallback(async () => {
    const body = {
      departmentId,
      status: SALES_PLAN_STATUS_COMPLETED,
      completedStep: SALES_PLAN_STEP_RELATED_PRODUCTS
    }

    setIsLoading(true)
    await upsertSalesPlan(body, salesPlanId)
    goToSalesPlans()
  }, [departmentId, salesPlanId, upsertSalesPlan, goToSalesPlans])

  const onSaveDraft = useCallback(async () => {
    switch (currentStep) {
      case SALES_PLAN_STEP_BASIC_INFO:
        await submitBasicInfoForm()
        break
      case SALES_PLAN_STEP_QUESTIONS:
        const firstQuestion = questions[0]

        await saveDraft(SALES_PLAN_STEP_QUESTIONS)
        if (firstQuestion.text && firstQuestion.answers[0]?.text)
          await upsertMultipleQuestions()
        break
      case SALES_PLAN_STEP_QUESTIONS_FLOW:
        const firstLevel = questionsFlow[0]

        await saveDraft(SALES_PLAN_STEP_QUESTIONS_FLOW)
        if (
          firstLevel.questions.every(q => q.id) &&
          firstLevel.questions.every(q => q.answers.every(a => a.length > 0))
        )
          await upsertMultipleFlowLevels()
        break
      case SALES_PLAN_STEP_PRODUCTS:
        await saveDraft(SALES_PLAN_STEP_PRODUCTS)
        break
      case SALES_PLAN_STEP_PRODUCTS_ASSOCIATION:
        await saveDraft(SALES_PLAN_STEP_PRODUCTS_ASSOCIATION)
        if (userFlows.some(uf => uf.productIds.length > 0))
          await upsertMultipleProductsTags()
        break
      case SALES_PLAN_STEP_RELATED_PRODUCTS:
        await saveDraft(SALES_PLAN_STEP_RELATED_PRODUCTS)
        break
      default:
        return
    }

    setNeedsCloseConfirm(false)
    renderSuccessMessage('salesPlan.message.saveDraft', {
      salesPlanName: basicInfoFormValues.name
    })
  }, [
    currentStep,
    basicInfoFormValues,
    questions,
    questionsFlow,
    userFlows,
    saveDraft,
    submitBasicInfoForm,
    upsertMultipleQuestions,
    upsertMultipleFlowLevels,
    upsertMultipleProductsTags,
    renderSuccessMessage
  ])

  const renderSteps = useCallback(() => {
    switch (currentStep) {
      case SALES_PLAN_STEP_BASIC_INFO:
        return (
          <BasicInfoStep
            initialValues={basicInfoFormValues}
            onNext={onBasicInfoStepNext}
            onValuesChange={onBasicInfoFormValuesChange}
          />
        )
      case SALES_PLAN_STEP_QUESTIONS:
        return (
          <QuestionsStep
            questions={questions}
            hasFlow={hasFlow}
            hasAssociation={hasAssociation}
            addQuestion={addQuestion}
            updateQuestion={updateQuestion}
            deleteQuestion={deleteQuestion}
            deleteAnswer={deleteAnswer}
            onNext={onQuestionsStepNext}
          />
        )
      case SALES_PLAN_STEP_QUESTIONS_FLOW:
        return (
          <QuestionsFlowStep
            questionsFlow={questionsFlow}
            questions={questions}
            hasAssociation={hasAssociation}
            addLevel={addQuestionsFlowLevel}
            updateLevel={updateQuestionsFlowLevel}
            deleteLevel={deleteQuestionsFlowLevel}
            onNext={onQuestionsFlowStepNext}
          />
        )
      case SALES_PLAN_STEP_PRODUCTS:
        return (
          <ProductsStep
            products={products}
            notFoundProducts={notFoundProducts}
            hasAssociation={hasAssociation}
            importProducts={importProducts}
            deleteProduct={deleteProduct}
            deleteMultipleProducts={deleteMultipleProducts}
            updateProduct={updateProduct}
            updateMultipleProducts={updateMultipleProducts}
            onNext={onProductsStepNext}
          />
        )
      case SALES_PLAN_STEP_PRODUCTS_ASSOCIATION:
        return (
          <ProductsAssociationStep
            userFlows={userFlows}
            products={products.filter(p => p.isCompleted)}
            updateUserFlow={updateUserFlow}
            upsertProductsTags={upsertProductsTags}
            onNext={onProductsAssociationStepNext}
          />
        )
      case SALES_PLAN_STEP_RELATED_PRODUCTS:
        return (
          <RelatedProductsStep
            relatedProducts={relatedProducts}
            notFoundRelatedProducts={notFoundRelatedProducts}
            products={products.filter(p => p.isCompleted)}
            importProducts={importRelatedProducts}
            deleteProduct={deleteRelatedProduct}
            deleteMultipleProducts={deleteMultipleRelatedProducts}
            updateProduct={updateRelatedProduct}
            updateMultipleProducts={updateMultipleRelatedProducts}
            onNext={saveCloseSalesPlan}
          />
        )
      default:
        return ''
    }
  }, [
    currentStep,
    hasFlow,
    hasAssociation,
    basicInfoFormValues,
    questions,
    questionsFlow,
    products,
    notFoundProducts,
    userFlows,
    relatedProducts,
    notFoundRelatedProducts,
    onBasicInfoFormValuesChange,
    onBasicInfoStepNext,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    deleteAnswer,
    onQuestionsStepNext,
    addQuestionsFlowLevel,
    updateQuestionsFlowLevel,
    deleteQuestionsFlowLevel,
    onQuestionsFlowStepNext,
    importProducts,
    deleteProduct,
    deleteMultipleProducts,
    updateProduct,
    updateMultipleProducts,
    onProductsStepNext,
    updateUserFlow,
    upsertProductsTags,
    onProductsAssociationStepNext,
    importRelatedProducts,
    deleteRelatedProduct,
    deleteMultipleRelatedProducts,
    updateRelatedProduct,
    updateMultipleRelatedProducts,
    saveCloseSalesPlan
  ])

  return (
    <>
      <ActionsRow>
        <Breadcrumb
          currentPage={salesPlanId ? 'editSalesPlan' : 'createSalesPlan'}
          needsCloseConfirm={needsCloseConfirm}
          data={{
            departmentId,
            departmentName: currentDepartment.name,
            salesPlanName: basicInfoFormValues.name
          }}
        />
        <Space>
          <Button type={'text'} size={'small'} onClick={closeSalesPlanCallback}>
            <FormattedMessage id={'salesPlan.button.close'} />
          </Button>
          <Button
            type={'link'}
            size={'small'}
            onClick={onSaveDraft}
            disabled={isSaveDraftDisabled()}>
            <FormattedMessage id={'salesPlan.button.saveDraft'} />
          </Button>
        </Space>
      </ActionsRow>
      <PageHeader>
        <PageTitle>
          <FormattedMessage id={`salesPlan.title.${pageMode}`} />
        </PageTitle>
      </PageHeader>
      <Stepper
        currentIndex={currentStep - 1}
        completedIndex={completedStep - 1}
        onChange={onStepChange}
      />
      {isLoading ? <Loader height={'400px'} /> : renderSteps()}
    </>
  )
}
const mapStateToProps = state => ({
  departments: state.app.departments
})
const mapDispatchToProps = {
  upsertSalesPlan,
  getSalesPlanAction: getSalesPlan,
  getBasicInfoAction: getBasicInfo,
  getQuestionsAction: getQuestions,
  upsertMultipleQuestionsAction: upsertMultipleQuestions,
  upsertQuestion,
  deleteQuestionAction: deleteQuestion,
  deleteAnswerAction: deleteAnswer,
  getQuestionsFlowAction: getQuestionsFlow,
  upsertMultipleFlowLevelsAction: upsertMultipleFlowLevels,
  upsertFlowLevel,
  deleteFlowLevel,
  getUserFlowsAction: getUserFlows,
  getProductsAction: getProducts,
  importProductsAction: importProducts,
  updateProductAction: updateProduct,
  updateMultipleProductsAction: updateMultipleProducts,
  deleteProductAction: deleteProduct,
  deleteMultipleProductsAction: deleteMultipleProducts,
  upsertProductsTagsAction: upsertProductsTags,
  upsertMultipleProductsTagsAction: upsertMultipleProductsTags,
  getRelatedProductsAction: getRelatedProducts,
  importRelatedProductsAction: importRelatedProducts,
  updateRelatedProductAction: updateRelatedProduct,
  updateMultipleRelatedProductsAction: updateMultipleRelatedProducts,
  deleteRelatedProductAction: deleteRelatedProduct,
  deleteMultipleRelatedProductsAction: deleteMultipleRelatedProducts
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SalesPlan))
