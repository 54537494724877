import { useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { Card, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, WarningFilled } from '@ant-design/icons'

import picture from 'src/assets/img/picture.png'
import { HoverCheckbox } from './styled'

import { colors } from 'src/common/constants'

const ProductCard = props => {
  const { intl, product, onEdit, onDelete, onSelectChange } = props

  const { id, text, imgUrl, isCompleted, price, unit } = product

  const onSelectChangeCallback = useCallback(
    e => {
      const { value, checked } = e.target
      onSelectChange(value, checked)
    },
    [onSelectChange]
  )

  const onEditCallback = useCallback(() => onEdit(product), [product, onEdit])

  const onDeleteCallback = useCallback(() => onDelete(id), [id, onDelete])

  return (
    <Card
      cover={
        <>
          <HoverCheckbox value={id} onChange={onSelectChangeCallback} />
          <img src={imgUrl || picture} alt={text} />
        </>
      }
      bodyStyle={{ padding: 16 }}
      actions={[
        <span
          key={'edit'}
          style={{
            color: isCompleted ? colors.success : colors.warning
          }}
          onClick={onEditCallback}>
          <EditOutlined />
          {!isCompleted ? (
            <WarningFilled style={{ fontSize: 10, position: 'absolute' }} />
          ) : null}
        </span>,
        <DeleteOutlined key={'delete'} onClick={onDeleteCallback} />
      ]}>
      <Card.Meta
        title={
          <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
        }
        description={
          <>
            <p style={{ margin: 0 }}>
              <em>{`ref. ${id}`}</em>
            </p>
            <strong>{`${price} ${intl.formatMessage({ id: `product.priceUnit.${unit}` })}`}</strong>
          </>
        }
      />
    </Card>
  )
}
export default injectIntl(ProductCard)
