export const colors = {
  main: '#66CC33',
  white: '#FFF',
  grey1: '#F0F0F0',
  grey2: '#D9D9D9',
  grey3: '#B9B9B9',
  grey4: '#6D7278',
  success: '#52c41a',
  warning: '#faad14',
  error: '#f5222d',
}
