import styled from 'styled-components'
import { colors } from '../../common/constants'

export const UserContainer = styled.div`
  justify-self: end;
`
export const Username = styled.small`
  color: ${colors.main};
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 0.5rem;
`
