import itIT from 'antd/lib/locale/it_IT'
import enGB from 'antd/lib/locale/en_US'

import moment from 'moment'
import 'moment/locale/it'
import 'moment/locale/en-gb'

import it from '../translations/it.json'
import en from '../translations/en.json'

const translations = { it, en }
const antTranslations = { it: itIT, en: enGB }
const momentTranslations = { it: 'it', en: 'en_gb' }
const language = navigator.language.split(/[-_]/)[0] // language without region code

export const locale = translations[language] ? language : 'it'
export const antLocale = antTranslations[language]
  ? antTranslations[language]
  : itIT
const momentLocale = momentTranslations[language]
  ? momentTranslations[language]
  : 'it'
export const messages = translations[language] || it

moment.locale(momentLocale)
