import PropTypes from 'prop-types'
import { RadioButton, RadioButtonContent, RadioGroup } from './styled'

const RadioGrid = props => {
  const { items, columns, imgHeight, onChange } = props
  return (
    <RadioGroup
      columns={columns}
      buttonStyle={'solid'}
      onChange={e => onChange(e.target.value)}>
      {items.map(item => {
        const { id, name, text, imgUrl, description } = item
        const TextTag = description ? 'h4' : 'p'
        return (
          <RadioButton key={id} value={id}>
            <RadioButtonContent hasImg={imgUrl}>
              <>
                {imgUrl ? (
                  <img src={imgUrl} alt={id} height={imgHeight} />
                ) : null}
                <div>
                  <TextTag>{name || text}</TextTag>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                    }}
                  />
                </div>
              </>
            </RadioButtonContent>
          </RadioButton>
        )
      })}
    </RadioGroup>
  )
}
RadioGrid.propTypes = {
  columns: PropTypes.object,
  imgHeight: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func
}
export default RadioGrid
