export const BU = 5
export const DATE_FORMAT = 'DD/MM/YYYY'

export const SALES_PLAN_STATUS_DRAFT = 'DRAFT'
export const SALES_PLAN_STATUS_COMPLETED = 'COMPLETED'
export const SALES_PLAN_STATUS_PUBLISHED = 'PUBLISHED'

export const SALES_PLAN_STEP_BASIC_INFO = 1
export const SALES_PLAN_STEP_QUESTIONS = 2
export const SALES_PLAN_STEP_QUESTIONS_FLOW = 3
export const SALES_PLAN_STEP_PRODUCTS = 4
export const SALES_PLAN_STEP_PRODUCTS_ASSOCIATION = 5
export const SALES_PLAN_STEP_RELATED_PRODUCTS = 6

export const EDIT_MODE_SINGLE = 'single'
export const EDIT_MODE_MULTI = 'multi'

export const OPTION_NO_ASSOCIABLE_PRODUCTS = '0'