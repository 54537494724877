import { FormattedMessage } from 'react-intl'
import BasicInfoForm from 'src/components/BasicInfoForm'

const BasicInfoStep = props => {
  const { initialValues, onNext, onValuesChange } = props
  return (
    <>
      <h3>
        <FormattedMessage id={'salesPlan.step.title.basicInfo'} />
      </h3>
      <BasicInfoForm
        initialValues={initialValues}
        onSubmit={onNext}
        onValuesChange={onValuesChange}
      />
    </>
  )
}
export default BasicInfoStep
