import { GET_DEPARTMENTS, GET_SALES_PLANS } from '../action-types'

const initialState = {
  currentDepartment: undefined,
  departments: [],
  salesPlans: []
}
const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DEPARTMENTS:
      return { ...state, departments: payload }
    case GET_SALES_PLANS:
      return { ...state, salesPlans: payload }
    default:
      return state
  }
}
export default appReducer
