import { useCallback, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Card, Col, Form, Input, Modal, Row } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import { FormItem } from 'src/common/styled'

import { colors } from 'src/common/constants'
import { AnswerFormItem } from './styled'

const Question = props => {
  const {
    intl,
    index,
    initialValues,
    hasFlow,
    hasAssociation,
    isDeletable,
    onValuesChange,
    onDelete,
    deleteAnswer
  } = props
  const headStyle = { backgroundColor: colors.main, color: colors.white }

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  const removeAnswerCallback = useCallback(
    (name, removeFunc) => {
      const questionLevel = initialValues.level
      const answerId = initialValues.answers[name].id

      return Modal.confirm({
        title: intl.formatMessage({ id: 'answer.confirm.delete.title' }),
        content: intl.formatMessage({
          id: `answer.confirm.delete.content${
            hasAssociation ? '.withAssociation' : hasFlow ? '.withFlow' : ''
          }`
        }),
        okText: intl.formatMessage({ id: 'answer.confirm.delete.okText' }),
        cancelText: intl.formatMessage({
          id: 'answer.confirm.delete.cancelText'
        }),
        onOk: () => {
          removeFunc(name)
          if (answerId) deleteAnswer(answerId, questionLevel)
        }
      })
    },
    [intl, hasFlow, hasAssociation, initialValues, deleteAnswer]
  )

  return (
    <Card
      size={'small'}
      title={intl.formatMessage({ id: 'question.card.title' }, { index })}
      extra={
        // TODO: Popconfirm / Modal.confirm with alert
        isDeletable ? (
          <Button
            icon={<DeleteOutlined />}
            size={'small'}
            noShadow
            onClick={onDelete}>
            <FormattedMessage id={'question.button.deleteQuestion'} />
          </Button>
        ) : null
      }
      headStyle={headStyle}>
      <Form
        form={form}
        layout={'vertical'}
        initialValues={initialValues}
        onValuesChange={onValuesChange}>
        <FormItem
          name={'text'}
          label={intl.formatMessage({ id: 'question.form.label.question' })}
          required={true}>
          <Input />
        </FormItem>

        <Form.List name={'answers'}>
          {(fields, { add, remove }) => (
            <Row gutter={16}>
              {fields.map(({ key, name, ...restField }, index) => (
                <Col xs={24} xl={12} key={key}>
                  <AnswerFormItem>
                    <FormItem
                      {...restField}
                      name={[name, 'text']}
                      label={intl.formatMessage(
                        { id: 'question.form.label.answerText' },
                        { index: index + 1 }
                      )}
                      required>
                      <Input />
                    </FormItem>
                    <FormItem
                      {...restField}
                      name={[name, 'description']}
                      label={intl.formatMessage({
                        id: 'question.form.label.answerDescription'
                      })}>
                      <Input />
                    </FormItem>
                    {fields.length > 1 ? (
                      <DeleteOutlined
                        onClick={() => removeAnswerCallback(name, remove)}
                      />
                    ) : null}
                  </AnswerFormItem>
                </Col>
              ))}
              <Col span={24}>
                <Button
                  type={'text'}
                  icon={<PlusCircleOutlined />}
                  onClick={() => add()}>
                  <FormattedMessage id={'question.button.addAnswer'} />
                </Button>
              </Col>
            </Row>
          )}
        </Form.List>
      </Form>
    </Card>
  )
}
export default injectIntl(Question)
