import { useCallback } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ActionsRow } from 'src/common/styled'
import { Modal } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import Question from 'src/components/Question'

const QuestionsStep = props => {
  const {
    intl,
    questions,
    hasFlow,
    hasAssociation,
    addQuestion,
    updateQuestion,
    deleteQuestion,
    deleteAnswer,
    onNext
  } = props

  const firstQuestion = questions[0]
  const lastQuestion = questions[questions.length - 1]
  const isAddQuestionDisabled =
    !lastQuestion.text || !lastQuestion.answers[0]?.text
  const isNextDisabled = !firstQuestion.text || !firstQuestion.answers[0]?.text

  const deleteQuestionCallback = useCallback(
    index => {
      return Modal.confirm({
        title: intl.formatMessage(
          { id: 'question.confirm.delete.title' },
          { index: index + 1 }
        ),
        content: intl.formatMessage({
          id: `question.confirm.delete.content${
            hasAssociation ? '.withAssociation' : hasFlow ? '.withFlow' : ''
          }`
        }),
        okText: intl.formatMessage({ id: 'question.confirm.delete.okText' }),
        cancelText: intl.formatMessage({
          id: 'question.confirm.delete.cancelText'
        }),
        onOk: () => deleteQuestion(index)
      })
    },
    [intl, hasFlow, hasAssociation, deleteQuestion]
  )

  return (
    <>
      <h3>
        <FormattedMessage id={'salesPlan.step.title.questions'} />
      </h3>
      {questions.map((q, index) => (
        <Question
          key={index}
          index={index + 1}
          initialValues={q}
          hasFlow={hasFlow}
          hasAssociation={hasAssociation}
          isDeletable={questions.length > 1}
          onValuesChange={(_, questionValues) =>
            updateQuestion(index, questionValues)
          }
          onDelete={() => deleteQuestionCallback(index)}
          deleteAnswer={deleteAnswer}
        />
      ))}
      <ActionsRow>
        <Button
          type={'link'}
          icon={<PlusCircleOutlined />}
          disabled={isAddQuestionDisabled}
          onClick={addQuestion}>
          <FormattedMessage id={'question.button.addQuestion'} />
        </Button>
      </ActionsRow>
      <ActionsRow justify={'end'}>
        <Button disabled={isNextDisabled} onClick={onNext}>
          <FormattedMessage id={'salesPlan.button.goToStepQuestionsFlow'} />
        </Button>
      </ActionsRow>
    </>
  )
}
export default injectIntl(QuestionsStep)
