import styled from 'styled-components'
import { Card } from 'antd'
import { colors } from 'src/common/constants'

export const FormItemCard = styled(Card)`
  border-radius: 4px;
  margin-bottom: 16px;

  & .ant-card-body {
    border-left: 10px solid ${colors.main};
    padding: 16px;
    border-radius: 4px;
  }
`
