import { Tabs as AntdTabs } from 'antd'
import { colors } from 'src/common/constants'
import styled from 'styled-components'

export const Tabs = styled(AntdTabs)`
  & > .ant-tabs-nav {
    margin: 0;
  }
`
export const TabPane = styled(AntdTabs.TabPane)`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey1};
  border-top: none;
  padding: 16px;

  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`

export const RelatedQuestionContainer = styled.p`
  display: grid;
  gap: 8px;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  & > .anticon {
    color: ${colors.main};
  }
`

export const QuestionContainer = styled.div`
  padding: 16px;
  background-color: rgba(217, 217, 217, 0.15);
`
