import { useCallback, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Select } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'

import {
  QuestionContainer,
  RelatedQuestionContainer,
  TabPane,
  Tabs
} from './styled'

const QuestionsFlowQuestion = props => {
  const {
    intl,
    question,
    questions,
    onQuestionChange,
    onAnswerChange,
    addTab,
    removeTab
  } = props
  const [selectedQuestion, setSelectedQuestion] = useState(
    questions.find(q => q.id === +question.id)
  )
  const [activeKey, setActiveKey] = useState('0')

  const onQuestionChangeCallback = useCallback(
    questionId => {
      setSelectedQuestion(questions.find(q => q.id === +questionId))
      onQuestionChange(questionId)
    },
    [questions, onQuestionChange]
  )

  const onChangeTab = useCallback(activeKey => setActiveKey(activeKey), [])

  const onEditTab = useCallback(
    (tabIndex, action) => {
      if (action === 'add') {
        addTab()
        setActiveKey((question.answers.length - 1).toString())
      }
      if (action === 'remove') {
        removeTab(tabIndex)
        setActiveKey((tabIndex - 1).toString())
      }
    },
    [question, addTab, removeTab]
  )

  return (
    <QuestionContainer>
      {question.relatedQuestion ? (
        <RelatedQuestionContainer>
          <CaretRightOutlined />
          <span>{question.relatedQuestion.text}</span>
          <span>{question.relatedAnswers.map(a => a.text).join(' - ')}</span>
        </RelatedQuestionContainer>
      ) : null}
      <Tabs
        type={'editable-card'}
        size={'small'}
        activeKey={activeKey}
        onChange={onChangeTab}
        onEdit={onEditTab}>
        {question.answers.map((qa, index) => (
          <TabPane
            key={index}
            tab={intl.formatMessage(
              { id: 'questionsFlow.tab.title' },
              { index: index + 1 }
            )}
            closable={index > 0}>
            <Select
              value={question.id}
              disabled={index > 0}
              placeholder={intl.formatMessage({
                id: 'questionsFlow.placeholder.question'
              })}
              onChange={onQuestionChangeCallback}>
              {questions.map(q => (
                <Select.Option key={`q-${q.id}`} value={q.id}>
                  {q.text}
                </Select.Option>
              ))}
            </Select>
            <Select
              mode={'multiple'}
              value={qa}
              disabled={!selectedQuestion}
              maxTagCount={'responsive'}
              placeholder={intl.formatMessage({
                id: 'questionsFlow.placeholder.answer'
              })}
              onChange={aId => onAnswerChange(aId, index)}>
              {selectedQuestion
                ? selectedQuestion.answers.map(a => (
                    <Select.Option key={`a-${a.id}`} value={a.id}>
                      {a.text}
                    </Select.Option>
                  ))
                : null}
            </Select>
          </TabPane>
        ))}
      </Tabs>
    </QuestionContainer>
  )
}
export default injectIntl(QuestionsFlowQuestion)
