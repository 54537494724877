import {
  DELETE_ANSWER,
  DELETE_QUESTION,
  GET_QUESTIONS,
  UPSERT_MULTIPLE_QUESTIONS,
  UPSERT_QUESTION
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getQuestions = salesPlanId => async dispatch => {
  try {
    const res = await api.question.list(salesPlanId)
    dispatch({ type: GET_QUESTIONS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertMultipleQuestions = body => async dispatch => {
  try {
    await api.question.upsertMultiple(body)
    return dispatch({ type: UPSERT_MULTIPLE_QUESTIONS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertQuestion = body => async dispatch => {
  try {
    const res = await api.question.upsert(body)
    dispatch({ type: UPSERT_QUESTION })
    return res.data.id
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteQuestion = questionId => async dispatch => {
  try {
    const res = await api.question.delete(questionId)
    dispatch({ type: DELETE_QUESTION })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteAnswer = answerId => async dispatch => {
  try {
    const res = await api.answer.delete(answerId)
    dispatch({ type: DELETE_ANSWER })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}
