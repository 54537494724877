import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Auth from '../Auth'
import Home from '../Home'
import SalesPlans from '../SalesPlans'
import SalesPlan from '../SalesPlan'
import Page404 from '../404'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import FeedbackButton from 'src/components/FeedbackButton'
import { WhiteLayout, PaddedContent } from '../../common/styled'

const App = props => {
  const { user } = props

  const tangram = useRef(
    new window.Tangram({
      productId: '463ac5f3-913d-4886-a5da-7755e91c574f', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true
      }
    })
  )

  return (
    <WhiteLayout>
      <Header user={user} />
      <Auth>
        <PaddedContent>
          <Switch>
            <Route component={Home} exact path={'/'} />
            <Route component={SalesPlan} path={'/sales-plans/new'} />
            <Route component={SalesPlan} path={'/sales-plans/:salesPlanId'} />
            <Route component={SalesPlans} path={'/sales-plans'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
      </Auth>
      <Footer />
      <FeedbackButton onClick={() => tangram.current.open()} />
    </WhiteLayout>
  )
}
App.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.user
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(App)
