import {
  DELETE_MULTIPLE_RELATED_PRODUCTS,
  DELETE_RELATED_PRODUCT,
  GET_RELATED_PRODUCTS,
  IMPORT_RELATED_PRODUCTS,
  UPDATE_MULTIPLE_RELATED_PRODUCTS,
  UPDATE_RELATED_PRODUCT
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getRelatedProducts = salesPlanId => async dispatch => {
  try {
    const res = await api.relatedProduct.list(salesPlanId)
    dispatch({ type: GET_RELATED_PRODUCTS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const importRelatedProducts = (body, salesPlanId) => async dispatch => {
  try {
    const res = await api.relatedProduct.import(body, salesPlanId)
    dispatch({ type: IMPORT_RELATED_PRODUCTS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateRelatedProduct = (body, productId) => async dispatch => {
  try {
    await api.relatedProduct.update(body, productId)
    return dispatch({ type: UPDATE_RELATED_PRODUCT })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateMultipleRelatedProducts = body => async dispatch => {
  try {
    const res = await api.relatedProduct.updateMultiple(body)
    dispatch({ type: UPDATE_MULTIPLE_RELATED_PRODUCTS })
    return { status: res.status, count: res.data.count }
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteRelatedProduct = productId => async dispatch => {
  try {
    const res = await api.relatedProduct.delete(productId)
    dispatch({ type: DELETE_RELATED_PRODUCT })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteMultipleRelatedProducts = body => async dispatch => {
  try {
    const res = await api.relatedProduct.deleteMultiple(body)
    dispatch({ type: DELETE_MULTIPLE_RELATED_PRODUCTS })
    return { status: res.status, count: res.data.count }
  } catch (error) {
    return handleServerError(error)
  }
}
