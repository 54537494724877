import { GET_DEPARTMENTS, GET_SALES_PLANS } from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const getDepartments = () => async dispatch => {
  try {
    const res = await api.department.list()
    return dispatch({ type: GET_DEPARTMENTS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getSalesPlans = departmentId => async dispatch => {
  try {
    const res = await api.salesPlan.list(departmentId)
    return dispatch({ type: GET_SALES_PLANS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
