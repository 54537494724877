import { Alert, Form, Radio, Select, Space } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FormItem } from 'src/common/styled'

const RelatedProductForm = props => {
  const { intl, form, initialValues, products, notExistingProducts, required, onValuesChange } =
    props
  return (
    <Form
      form={form}
      layout={'vertical'}
      initialValues={initialValues}
      onValuesChange={onValuesChange}>
      <FormItem
        name={'type'}
        label={intl.formatMessage({ id: 'relatedProduct.form.label.type' })}
        required={required}>
        <Radio.Group>
          <Space>
            <Radio value={'product'}>
              <FormattedMessage id={'relatedProduct.radio.product'} />
            </Radio>
            <Radio value={'pose'}>
              <FormattedMessage id={'relatedProduct.radio.pose'} />
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      <FormItem
        name={'productIds'}
        label={intl.formatMessage({
          id: 'relatedProduct.form.label.productIds'
        })}
        required={required}>
        <Select
          mode={'tags'}
          tokenSeparators={[' ', ',', '\n', '\t']}
          // maxTagCount={3}
        >
          {products.map(p => (
            <Select.Option key={p.id}>{`${p.id} - ${p.text}`}</Select.Option>
          ))}
        </Select>
      </FormItem>
      {notExistingProducts?.length > 0 ? (
        <Alert
          message={intl.formatMessage(
            { id: 'error.notExistingProducts' },
            { products: notExistingProducts.join(', ') }
          )}
          type={'error'}
          banner
          showIcon
          closable
        />
      ) : null}
    </Form>
  )
}

export default injectIntl(RelatedProductForm)
