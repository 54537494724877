import styled from 'styled-components'
import { Form, Layout, Tabs } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px 48px;
  min-height: calc(100vh - 112px);
  display: grid;
  gap: 32px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
`
export const PageHeader = styled.div`
  text-align: center;
`
export const PageTitle = styled.h1`
  color: ${colors.main};
`
export const ActionsRow = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    `repeat(${props.children.length || 1}, auto)`};
  justify-content: ${props => props.justify || 'space-between'};
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 8px !important;
`
export const DynamicFieldContent = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
  align-items: center;
`

export const BorderedTabs = styled(Tabs)`
  & > .ant-tabs-nav {
    margin: 0;
  }
`
export const BorderedTabPane = styled(Tabs.TabPane)`
  border: 1px solid #f0f0f0;
  border-top: none;
  padding: 16px;
`
