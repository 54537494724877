import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'
import {
  DELETE_FLOW_LEVEL,
  GET_QUESTIONS_FLOW,
  GET_USER_FLOWS,
  UPSERT_FLOW_LEVEL,
  UPSERT_MULTIPLE_FLOW_LEVELS
} from '../action-types'

export const getQuestionsFlow = salesPlanId => async dispatch => {
  try {
    const res = await api.flow.questionsFlow(salesPlanId)
    dispatch({ type: GET_QUESTIONS_FLOW })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertMultipleFlowLevels = body => async dispatch => {
  try {
    await api.flow.upsertMultipleLevels(body)
    return dispatch({ type: UPSERT_MULTIPLE_FLOW_LEVELS })
  } catch (error) {
    return handleServerError(error)
  }
}

export const upsertFlowLevel = body => async dispatch => {
  try {
    await api.flow.upsertLevel(body)
    dispatch({ type: UPSERT_FLOW_LEVEL })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteFlowLevel = body => async dispatch => {
  try {
    const res = await api.flow.deleteLevel(body)
    dispatch({ type: DELETE_FLOW_LEVEL })
    return { status: res.status }
  } catch (error) {
    return handleServerError(error)
  }
}

export const getUserFlows = salesPlanId => async dispatch => {
  try {
    const res = await api.flow.userFlows(salesPlanId)
    dispatch({ type: GET_USER_FLOWS })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}
