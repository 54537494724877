import { useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { Avatar } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import { Steps } from './styled'
import { colors } from 'src/common/constants'

const Stepper = props => {
  const { intl, currentIndex, completedIndex, onChange } = props
  const steps = [
    'basicInfo',
    'questions',
    'questionsFlow',
    'products',
    'productsAssociation',
    'relatedProducts'
  ]

  const renderStepIcon = useCallback(
    index => {
      const stepIcon = index <= completedIndex ? <CheckOutlined /> : index + 1

      let stepStyle = {
        backgroundColor: 'transparent',
        color: colors.grey3,
        border: `1px solid ${colors.grey3}`
      }

      if (index < currentIndex)
        stepStyle = {
          backgroundColor: 'transparent',
          color: colors.main,
          border: `1px solid ${colors.main}`
        }

      if (index === currentIndex)
        stepStyle = {
          backgroundColor: colors.main,
          color: colors.white,
          border: `1px solid ${colors.main}`
        }

      return <Avatar size={'small'} icon={stepIcon} style={stepStyle} />
    },
    [currentIndex, completedIndex]
  )

  return (
    <Steps
      current={currentIndex}
      size={'small'}
      labelPlacement={'vertical'}
      onChange={index => onChange(index + 1)}>
      {steps.map((step, index) => (
        <Steps.Step
          title={intl.formatMessage({ id: `stepper.step.${step}` })}
          disabled={index !== currentIndex && index > completedIndex}
          icon={renderStepIcon(index)}
        />
      ))}
    </Steps>
  )
}
export default injectIntl(Stepper)
