import { useCallback, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Form, Modal } from 'antd'

import RelatedProductForm from 'src/components/RelatedProductForm'

const EditRelatedProductModal = props => {
  const { intl, isVisible, initialValues, products, onSubmit, onClose } = props
  const [formValues, setFormValues] = useState(initialValues)
  const [notExistingProducts, setNotExistingProducts] = useState([])
  const isSubmitDisabled =
    !formValues.type ||
    !formValues.productIds ||
    formValues.productIds.length === 0

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      setFormValues(initialValues)
      form.setFieldsValue(initialValues)
    }
    if (!isVisible) {
      form.resetFields()
      setNotExistingProducts([])
    }
  }, [isVisible, initialValues, form])

  useEffect(() => {
    form.setFieldsValue(formValues)
  }, [form, formValues])

  const onValuesChangeCallback = useCallback(
    (_, allValues) => {
      const existingProducts = allValues.productIds.filter(pId =>
        products.find(p => p.id === +pId)
      )
      const notExistingProducts = allValues.productIds.filter(
        pId => !products.find(p => p.id === +pId)
      )

      setNotExistingProducts(notExistingProducts)

      const newValues = { ...allValues, productIds: existingProducts }
      setFormValues(prevValues => ({ ...prevValues, ...newValues }))
    },
    [products]
  )

  return (
    <Modal
      visible={isVisible}
      title={intl.formatMessage(
        { id: 'relatedProduct.editModal.title' },
        { product: initialValues.text }
      )}
      okText={intl.formatMessage({ id: 'relatedProduct.editModal.okText' })}
      cancelText={intl.formatMessage({
        id: 'relatedProduct.editModal.cancelText'
      })}
      okButtonProps={{ disabled: isSubmitDisabled }}
      onOk={() => onSubmit(formValues)}
      onCancel={onClose}>
      <RelatedProductForm
        form={form}
        initialValues={initialValues}
        products={products}
        notExistingProducts={notExistingProducts}
        required={true}
        onValuesChange={onValuesChangeCallback}
      />
    </Modal>
  )
}
export default injectIntl(EditRelatedProductModal)
