const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-piani-di-vendita-itlm-ping-y57xk5ws6a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080'
  },
  test: {
    LOGIN_URL: `https://test-lmit-piani-di-vendita-itlm-ping-y57xk5ws6a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-piani-di-vendita-api-y57xk5ws6a-ew.a.run.app'
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-piani-di-vendita-itlm-ping-y57xk5ws6a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-piani-di-vendita-api-y57xk5ws6a-ew.a.run.app'
  }
}

export default envConfig[env]
