import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import UserFlowAccordion from 'src/components/UserFlowAccordion'
import { ActionsRow } from 'src/common/styled'
import { StyledCollapse } from './styled'

const ProductsAssociationStep = props => {
  const { userFlows, products, updateUserFlow, upsertProductsTags, onNext } =
    props
  const [activePanel, setActivePanel] = useState(undefined)

  const isNextDisabled = userFlows.some(uf => uf.productIds.length === 0)

  const renderCollapseIcon = useCallback(({ isActive }) => {
    return isActive ? <MinusOutlined /> : <PlusOutlined />
  }, [])

  return (
    <>
      <h3>
        <FormattedMessage id={'salesPlan.step.title.productsAssociation'} />
      </h3>
      <StyledCollapse
        accordion
        activeKey={activePanel}
        expandIconPosition={'right'}
        expandIcon={renderCollapseIcon}
        onChange={setActivePanel}>
        {userFlows.map((uf, index) => (
          <UserFlowAccordion
            key={index}
            index={index + 1}
            userFlow={uf}
            products={products}
            onValuesChange={(_, userFlowValues) =>
              updateUserFlow(index, userFlowValues)
            }
            onSubmit={() => upsertProductsTags(index)}
          />
        ))}
      </StyledCollapse>
      <ActionsRow justify={'end'}>
        <Button disabled={isNextDisabled} onClick={onNext}>
          <FormattedMessage id={'salesPlan.button.goToStepRelatedProducts'} />
        </Button>
      </ActionsRow>
    </>
  )
}
export default ProductsAssociationStep
