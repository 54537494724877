import { useState, useEffect, useCallback } from 'react'
import { isEmpty } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Empty, Input, List, Modal } from 'antd'
import {
  /*CopyOutlined,*/ DeleteOutlined,
  EditOutlined,
  RollbackOutlined,
  SendOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'

import { getSalesPlans } from 'src/redux-store/actions/app-actions'
import {
  deleteSalesPlan,
  updateSalesPlanStatus
} from 'src/redux-store/actions/salesPlan-actions'

import Loader from 'src/components/Loader'
import Button from 'src/components/Button'
import Breadcrumb from 'src/components/Breadcrumb'
import Card from 'src/components/Card'
import IconButton from 'src/components/IconButton'
import { ActionsRow, PageHeader, PageTitle } from 'src/common/styled'

import {
  colors,
  DATE_FORMAT,
  SALES_PLAN_STATUS_DRAFT,
  SALES_PLAN_STATUS_COMPLETED,
  SALES_PLAN_STATUS_PUBLISHED
} from 'src/common/constants'
import { formatDate } from 'src/common/utils'

const EmptyComponent = injectIntl(({ intl }) => (
  <Empty description={intl.formatMessage({ id: 'salesPlans.empty.text' })} />
))

const SalesPlans = props => {
  const {
    intl,
    history,
    location,
    salesPlans,
    departments,
    getSalesPlansAction,
    deleteSalesPlanAction,
    updateSalesPlanStatusAction
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [filteredSalesPlans, setFilteredSalesPlans] = useState(undefined)

  const queryParams = new URLSearchParams(location.search)

  const [departmentId] = useState(queryParams.get('departmentId'))
  const [currentDepartment] = useState(
    departments.find(d => d.id === departmentId) || {}
  )

  const getSalesPlans = useCallback(
    async departmentId => {
      setIsLoading(true)
      await getSalesPlansAction(departmentId)
      setIsLoading(false)
    },
    [getSalesPlansAction]
  )

  useEffect(() => {
    if (isEmpty(currentDepartment)) {
      return history.push('/')
    }

    getSalesPlans(departmentId)
  }, [getSalesPlans, departmentId, currentDepartment, history])

  const deleteSalesPlan = useCallback(
    async salesPlanId => {
      await deleteSalesPlanAction(salesPlanId)
      getSalesPlans(departmentId)
    },
    [departmentId, getSalesPlans, deleteSalesPlanAction]
  )

  const deleteSalesPlanCallback = useCallback(
    salesPlan => {
      return Modal.confirm({
        title: intl.formatMessage({ id: 'salesPlans.confirm.delete.title' }),
        content: intl.formatMessage(
          {
            id: 'salesPlans.confirm.delete.content'
          },
          { salesPlanName: <strong>{salesPlan.name}</strong> }
        ),
        okText: intl.formatMessage({
          id: 'salesPlans.confirm.delete.okText'
        }),
        cancelText: intl.formatMessage({
          id: 'salesPlans.confirm.delete.cancelText'
        }),
        onOk: () => deleteSalesPlan(salesPlan.id)
      })
    },
    [intl, deleteSalesPlan]
  )

  const updateSalesPlanStatus = useCallback(
    async (status, salesPlanId) => {
      const body = { status }
      await updateSalesPlanStatusAction(body, salesPlanId)
      getSalesPlans(departmentId)
    },
    [departmentId, updateSalesPlanStatusAction, getSalesPlans]
  )

  const updateSalesPlanStatusCallback = useCallback(
    (status, salesPlan) => {
      return Modal.confirm({
        title: intl.formatMessage({ id: `salesPlans.confirm.${status}.title` }),
        content: intl.formatMessage(
          {
            id: `salesPlans.confirm.${status}.content`
          },
          { salesPlanName: <strong>{salesPlan.name}</strong> }
        ),
        okText: intl.formatMessage({
          id: `salesPlans.confirm.${status}.okText`
        }),
        cancelText: intl.formatMessage({
          id: `salesPlans.confirm.${status}.cancelText`
        }),
        onOk: () => updateSalesPlanStatus(status, salesPlan.id)
      })
    },
    [intl, updateSalesPlanStatus]
  )

  const filterSalesPlans = useCallback(
    e => {
      const filtered = salesPlans.filter(sp =>
        sp.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
      setFilteredSalesPlans(filtered)
    },
    [salesPlans]
  )

  const renderStatus = useCallback(salesPlan => {
    const { status, completedStep, updatedAt, publishedAt } = salesPlan
    let statusData = {}
    if (status === SALES_PLAN_STATUS_DRAFT)
      statusData = {
        color: colors.warning,
        translationsId: 'salesPlans.status.draft',
        translationsValues: { completedStep }
      }
    if (status === SALES_PLAN_STATUS_COMPLETED)
      statusData = {
        color: colors.success,
        translationsId: 'salesPlans.status.completed'
      }
    if (status === SALES_PLAN_STATUS_PUBLISHED)
      statusData = {
        color: colors.grey4,
        translationsId: 'salesPlans.status.published',
        translationsValues: { date: formatDate(publishedAt, DATE_FORMAT) }
      }

    return (
      <>
        <p style={{ color: statusData.color, margin: 0 }}>
          <FormattedMessage
            id={statusData.translationsId}
            values={statusData.translationsValues}
          />
        </p>
        <small>
          <FormattedMessage
            id={'salesPlans.updatedAt'}
            values={{ date: formatDate(updatedAt, DATE_FORMAT) }}
          />
        </small>
      </>
    )
  }, [])

  const goToNewSalesPlan = useCallback(() => {
    return history.push('/sales-plans/new', { departmentId })
  }, [history, departmentId])
  const goToEditSalesPlan = useCallback(
    (salesPlanId, completedStep) => {
      return history.push(`/sales-plans/${salesPlanId}`, {
        departmentId,
        completedStep
      })
    },
    [history, departmentId]
  )

  const showTotal = useCallback(
    total => (
      <FormattedMessage id={'salesPlans.totalItems'} values={{ total }} />
    ),
    []
  )

  if (isLoading) return <Loader />
  return (
    <>
      <Breadcrumb
        currentPage={'sales-plans'}
        data={{ departmentName: currentDepartment.name }}
      />
      <PageHeader>
        <PageTitle>
          <FormattedMessage
            id={'salesPlans.title'}
            values={{ department: currentDepartment.name }}
          />
        </PageTitle>
        <p>
          <FormattedMessage id={'salesPlans.text'} />
        </p>
      </PageHeader>
      <ActionsRow>
        <Input.Search
          placeholder={intl.formatMessage({
            id: 'salesPlans.placeholder.search'
          })}
          onChange={filterSalesPlans}
        />
        <Button onClick={goToNewSalesPlan}>
          <FormattedMessage id={'salesPlans.button.new'} />
        </Button>
      </ActionsRow>
      {salesPlans.length > 0 ? (
        <List
          grid={{ gutter: 16, column: 5 }}
          dataSource={filteredSalesPlans || salesPlans}
          pagination={{ showTotal }}
          locale={{ emptyText: <EmptyComponent /> }}
          renderItem={sp => (
            <List.Item>
              <Card
                key={sp.id}
                title={sp.name}
                description={renderStatus(sp)}
                actions={[
                  sp.status === SALES_PLAN_STATUS_PUBLISHED ? (
                    <IconButton
                      key={'unpublish'}
                      icon={<RollbackOutlined />}
                      onClick={() =>
                        updateSalesPlanStatusCallback(
                          SALES_PLAN_STATUS_COMPLETED,
                          sp
                        )
                      }
                    />
                  ) : (
                    <IconButton
                      key={'publish'}
                      disabled={sp.status === SALES_PLAN_STATUS_DRAFT}
                      icon={<SendOutlined />}
                      onClick={() =>
                        updateSalesPlanStatusCallback(
                          SALES_PLAN_STATUS_PUBLISHED,
                          sp
                        )
                      }
                    />
                  ),
                  <IconButton
                    key={'edit'}
                    icon={<EditOutlined />}
                    disabled={sp.status === SALES_PLAN_STATUS_PUBLISHED}
                    onClick={() => goToEditSalesPlan(sp.id, sp.completedStep)}
                  />,
                  <IconButton
                    key={'delete'}
                    icon={<DeleteOutlined />}
                    disabled={sp.status === SALES_PLAN_STATUS_PUBLISHED}
                    onClick={() => deleteSalesPlanCallback(sp)}
                  />
                ]}
              />
            </List.Item>
          )}
        />
      ) : (
        <EmptyComponent />
      )}
    </>
  )
}
const mapStateToProps = state => ({
  departments: state.app.departments,
  salesPlans: state.app.salesPlans
})
const mapDispatchToProps = {
  getSalesPlansAction: getSalesPlans,
  deleteSalesPlanAction: deleteSalesPlan,
  updateSalesPlanStatusAction: updateSalesPlanStatus
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SalesPlans))
