import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Form, Input, Modal } from 'antd'
import ProductForm from 'src/components/ProductForm'

const ImportProductsModal = props => {
  const { intl, isVisible, initialValues, onSubmit, onClose } = props
  const [step, setStep] = useState(1)
  const [productIds, setProductIds] = useState([])
  const [formValues, setFormValues] = useState(initialValues)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisible) {
      setFormValues(initialValues)
      form.setFieldsValue(initialValues)
    }
    if (!isVisible) form.resetFields()
  }, [isVisible, initialValues, form])

  const onValuesChange = useCallback(
    (_, allValues) =>
      setFormValues(prevValues => ({ ...prevValues, ...allValues })),
    []
  )

  const onProductIdsChange = useCallback(e => {
    const productIds = e.target.value
      .split(/[., |"';/\t/\n-]/)
      .filter(value => value.match(/[0-9]+/))

    setProductIds(productIds)
  }, [])

  const onOkCallback = useCallback(() => {
    if (step === 1) setStep(2)
    if (step === 2) {
      onSubmit(productIds, formValues)
      setProductIds([])
    }
  }, [step, productIds, formValues, onSubmit])

  const onCancelCallback = useCallback(() => {
    if (step === 1) {
      setProductIds([])
      onClose()
    }
    if (step === 2) setStep(1)
  }, [step, onClose])

  const renderModalContent = useCallback(() => {
    if (step === 1)
      return (
        <Input.TextArea
          defaultValue={productIds.join(' ')}
          onChange={onProductIdsChange}
        />
      )
    if (step === 2)
      return (
        <ProductForm
          form={form}
          initialValues={initialValues}
          required={false}
          onValuesChange={onValuesChange}
        />
      )
  }, [
    step,
    form,
    productIds,
    initialValues,
    onProductIdsChange,
    onValuesChange
  ])

  return (
    <Modal
      visible={isVisible}
      title={false}
      destroyOnClose
      okText={intl.formatMessage({
        id: `product.importModal.okText.step${step}`
      })}
      cancelText={intl.formatMessage({
        id: `product.importModal.cancelText.step${step}`
      })}
      onOk={onOkCallback}
      onCancel={onCancelCallback}
      okButtonProps={{ disabled: step === 1 && productIds.length === 0 }}>
      <h3>
        <FormattedMessage id={`product.importModal.title.step${step}`} />
      </h3>
      <p>
        <FormattedMessage id={`product.importModal.text.step${step}`} />
      </p>
      {renderModalContent()}
    </Modal>
  )
}
export default injectIntl(ImportProductsModal)
