import styled from 'styled-components'
import { Radio } from 'antd'

import { colors } from 'src/common/constants'

export const RadioGroup = styled(Radio.Group)`
  display: grid;
  gap: 16px;
  grid-template-columns: ${props => `repeat(${props.columns.xs || 1}, 1fr)`};

  @media screen and (min-width: 1200px) {
    grid-template-columns: ${props => `repeat(${props.columns.xl || 1}, 1fr)`};
  }
`
export const RadioButton = styled(Radio.Button)`
  color: ${colors.grey4};
  height: auto;
  padding: 16px;
  text-align: center;
  border-radius: 2px !important;
  border-left: 1px solid ${colors.grey2};
  &:not(:first-child)::before {
    display: none;
  }
  &.ant-radio-button-wrapper-checked img[height='30'] {
    filter: brightness(3);
  }
`
export const RadioButtonContent = styled.span`
  height: 100%;
  line-height: 1;
  text-align: ${props => (props.hasImg ? 'left' : 'center')};
  display: grid;
  gap: 12px;
  grid-template-columns: ${props => (props.hasImg ? 'auto 1fr' : 'auto')};
  align-items: center;

  & p {
    margin-bottom: 0.5em;
  }
`
