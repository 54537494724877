import { useCallback, useState } from 'react'
import { pick } from 'ramda'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Alert, Empty, Input, List, Modal, Space } from 'antd'

import Button from 'src/components/Button'
import ProductCard from 'src/components/ProductCard'
import ImportRelatedProductsModal from 'src/components/ImportRelatedProductsModal'
import EditRelatedProductModal from 'src/components/EditRelatedProductModal'
import { ActionsRow } from 'src/common/styled'

import { EDIT_MODE_MULTI, EDIT_MODE_SINGLE } from 'src/common/constants'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const initialProductFormValues = {
  type: undefined,
  productIds: undefined
}

const EmptyComponent = injectIntl(({ intl }) => (
  <Empty
    description={intl.formatMessage({ id: 'relatedProduct.empty.text' })}
  />
))

const RelatedProductsStep = props => {
  const {
    intl,
    relatedProducts,
    notFoundRelatedProducts,
    products,
    importProducts,
    deleteProduct,
    deleteMultipleProducts,
    updateProduct,
    updateMultipleProducts,
    onNext
  } = props

  const [editMode, setEditMode] = useState(EDIT_MODE_SINGLE)

  const [filteredProducts, setFilteredProducts] = useState(undefined)
  const [isImportModalVisible, setIsImportModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)

  const [productFormValues, setProductFormValues] = useState(
    initialProductFormValues
  )

  const [selectedProducts, setSelectedProducts] = useState([])

  const isMultiActionDisabled = selectedProducts.length === 0

  const filterProducts = useCallback(
    e => {
      const value = e.target.value.toLowerCase()
      const filtered = relatedProducts.filter(
        p =>
          p.text.toLowerCase().includes(value) ||
          p.id.toString().includes(value)
      )

      setFilteredProducts(filtered)
    },
    [relatedProducts]
  )

  const openImportModal = useCallback(() => setIsImportModalVisible(true), [])
  const closeImportModal = useCallback(() => setIsImportModalVisible(false), [])

  const openEditModal = useCallback((product, editMode) => {
    setProductFormValues(prevValues => ({
      ...prevValues,
      ...pick(['id', 'text', 'type', 'productIds'], product)
    }))
    if (editMode) setEditMode(editMode)
    setIsEditModalVisible(true)
  }, [])

  const closeEditModal = useCallback(() => {
    setIsEditModalVisible(false)
    setProductFormValues(initialProductFormValues)
  }, [])

  const updateSelectedProducts = useCallback((productId, checked) => {
    if (checked) {
      setSelectedProducts(prevProducts =>
        !prevProducts.includes(productId)
          ? [...prevProducts, productId]
          : prevProducts
      )
    } else {
      setSelectedProducts(prevProducts =>
        prevProducts.filter(pId => pId !== productId)
      )
    }
  }, [])

  const updateProductCallback = useCallback(
    formValues => {
      if (editMode === EDIT_MODE_SINGLE) return updateProduct(formValues)
      if (editMode === EDIT_MODE_MULTI)
        return updateMultipleProducts(selectedProducts, formValues)
    },
    [editMode, selectedProducts, updateProduct, updateMultipleProducts]
  )

  const deleteProductCallback = useCallback(
    productId => {
      return Modal.confirm({
        title: intl.formatMessage(
          { id: 'relatedProduct.confirm.delete.title' },
          { productId }
        ),
        content: intl.formatMessage(
          { id: 'relatedProduct.confirm.delete.content' },
          { productId }
        ),
        okText: intl.formatMessage({
          id: 'relatedProduct.confirm.delete.okText'
        }),
        cancelText: intl.formatMessage({
          id: 'relatedProduct.confirm.delete.cancelText'
        }),
        onOk: () => deleteProduct(productId)
      })
    },
    [intl, deleteProduct]
  )

  const deleteMultipleProductsCallback = useCallback(() => {
    const count = selectedProducts.length
    return Modal.confirm({
      title: intl.formatMessage(
        { id: 'relatedProduct.confirm.deleteMultiple.title' },
        { count }
      ),
      content: intl.formatMessage({
        id: 'relatedProduct.confirm.deleteMultiple.content'
      }),
      okText: intl.formatMessage({
        id: 'relatedProduct.confirm.deleteMultiple.okText'
      }),
      cancelText: intl.formatMessage({
        id: 'relatedProduct.confirm.deleteMultiple.cancelText'
      }),
      onOk: () => deleteMultipleProducts(selectedProducts)
    })
  }, [intl, selectedProducts, deleteMultipleProducts])

  const onNextCallback = useCallback(() => {
    return Modal.confirm({
      title: intl.formatMessage({ id: 'salesPlan.confirm.saveClose.title' }),
      content: intl.formatMessage({
        id: 'salesPlan.confirm.saveClose.content'
      }),
      okText: intl.formatMessage({ id: 'salesPlan.confirm.saveClose.okText' }),
      cancelText: intl.formatMessage({
        id: 'salesPlan.confirm.saveClose.cancelText'
      }),
      onOk: onNext
    })
  }, [intl, onNext])

  const showTotal = useCallback(
    total => (
      <FormattedMessage id={'relatedProduct.totalItems'} values={{ total }} />
    ),
    []
  )

  return (
    <>
      <ActionsRow>
        <Input.Search
          style={{ width: 360 }}
          placeholder={intl.formatMessage({
            id: 'relatedProduct.placeholder.search'
          })}
          onChange={filterProducts}
        />
        <Space>
          <Button
            type={'text'}
            icon={<DeleteOutlined />}
            disabled={isMultiActionDisabled}
            onClick={deleteMultipleProductsCallback}>
            <FormattedMessage id={'relatedProduct.button.deleteSelected'} />
          </Button>
          <Button
            type={'text'}
            icon={<EditOutlined />}
            disabled={isMultiActionDisabled}
            onClick={p => openEditModal(p, EDIT_MODE_MULTI)}>
            <FormattedMessage id={'relatedProduct.button.editSelected'} />
          </Button>
          <Button onClick={openImportModal}>
            <FormattedMessage id={'relatedProduct.button.import'} />
          </Button>
        </Space>
      </ActionsRow>
      {notFoundRelatedProducts.length > 0 ? (
        <Alert
          message={intl.formatMessage(
            { id: 'error.notFoundRelatedProducts' },
            { products: notFoundRelatedProducts.join(', ') }
          )}
          type={'error'}
          banner
          showIcon
          closable
        />
      ) : null}
      {relatedProducts.length > 0 ? (
        <List
          grid={{ gutter: 16, column: 6 }}
          dataSource={filteredProducts || relatedProducts}
          pagination={{ defaultPageSize: 18, showTotal }}
          locale={{
            emptyText: <EmptyComponent />
          }}
          renderItem={p => (
            <List.Item>
              <ProductCard
                product={p}
                onEdit={openEditModal}
                onDelete={deleteProductCallback}
                onSelectChange={updateSelectedProducts}
              />
            </List.Item>
          )}
        />
      ) : (
        <EmptyComponent />
      )}
      <EditRelatedProductModal
        isVisible={isEditModalVisible}
        initialValues={productFormValues}
        products={products}
        onSubmit={updateProductCallback}
        onClose={closeEditModal}
      />
      <ImportRelatedProductsModal
        isVisible={isImportModalVisible}
        initialValues={productFormValues}
        products={products}
        onSubmit={importProducts}
        onClose={closeImportModal}
      />
      <ActionsRow justify={'end'}>
        <Button onClick={onNextCallback}>
          <FormattedMessage id={'salesPlan.button.saveClose'} />
        </Button>
      </ActionsRow>
    </>
  )
}
export default injectIntl(RelatedProductsStep)
