import { useCallback } from 'react'
import { Breadcrumb as AntdBreadcrumb, Modal } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'

const Breadcrumb = props => {
  const { intl, currentPage, data, needsCloseConfirm } = props
  const { departmentId, departmentName, salesPlanName } = data

  const history = useHistory()

  const renderModalConfirm = useCallback(
    onOk => {
      return Modal.confirm({
        title: intl.formatMessage({ id: 'salesPlan.confirm.close.title' }),
        content: intl.formatMessage({
          id: 'salesPlan.confirm.close.content'
        }),
        okText: intl.formatMessage({ id: 'salesPlan.confirm.close.okText' }),
        cancelText: intl.formatMessage({
          id: 'salesPlan.confirm.close.cancelText'
        }),
        onOk: onOk
      })
    },
    [intl]
  )

  const goToHomeCallback = useCallback(() => {
    if (needsCloseConfirm) {
      const onOk = () => history.push('/')
      return renderModalConfirm(onOk)
    }
  }, [history, needsCloseConfirm, renderModalConfirm])

  const goToSalesPlansCallback = useCallback(() => {
    if (needsCloseConfirm) {
      const onOk = () =>
        history.push(`/sales-plans?departmentId=${departmentId}`)
      return renderModalConfirm(onOk)
    }
  }, [history, departmentId, needsCloseConfirm, renderModalConfirm])

  return (
    <AntdBreadcrumb>
      <AntdBreadcrumb.Item>
        <Link to={needsCloseConfirm ? {} : '/'} onClick={goToHomeCallback}>
          <FormattedMessage id={'breadcrumb.item.home'} />
        </Link>
      </AntdBreadcrumb.Item>
      <AntdBreadcrumb.Item>
        {currentPage === 'sales-plans' ? (
          departmentName
        ) : (
          <Link
            to={
              needsCloseConfirm
                ? {}
                : `/sales-plans?departmentId=${departmentId}`
            }
            onClick={goToSalesPlansCallback}>
            {departmentName}
          </Link>
        )}
      </AntdBreadcrumb.Item>
      {currentPage !== 'sales-plans' ? (
        <AntdBreadcrumb.Item>
          <FormattedMessage
            id={`breadcrumb.item.${currentPage}`}
            values={{ salesPlanName }}
          />
        </AntdBreadcrumb.Item>
      ) : null}
    </AntdBreadcrumb>
  )
}
export default injectIntl(Breadcrumb)
