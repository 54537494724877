import { injectIntl, FormattedMessage } from 'react-intl'
import { Form, Input, Radio, Space } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import Button from 'src/components/Button'
import { DynamicFieldContent, FormItem } from 'src/common/styled'

const ProductForm = props => {
  const { intl, form, initialValues, required, onValuesChange } = props
  return (
    <Form
      form={form}
      layout={'vertical'}
      initialValues={initialValues}
      onValuesChange={onValuesChange}>
      <FormItem
        name={'quartile'}
        label={intl.formatMessage({ id: 'product.form.label.quartile' })}
        required={required}>
        <Radio.Group>
          <Space>
            <Radio value={1}>
              <FormattedMessage id={'product.radio.quartile.start'} />
            </Radio>
            <Radio value={2}>
              <FormattedMessage id={'product.radio.quartile.standard'} />
            </Radio>
            <Radio value={3}>
              <FormattedMessage id={'product.radio.quartile.excellens'} />
            </Radio>
            <Radio value={4}>
              <FormattedMessage id={'product.radio.quartile.premium'} />
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      <FormItem
        name={'isLocal'}
        label={intl.formatMessage({ id: 'product.form.label.isLocal' })}
        required={required}>
        <Radio.Group>
          <Space>
            <Radio value={1}>
              <FormattedMessage id={'product.radio.isLocal.true'} />
            </Radio>
            <Radio value={0}>
              <FormattedMessage id={'product.radio.isLocal.false'} />
            </Radio>
          </Space>
        </Radio.Group>
      </FormItem>
      <Form.List name={'descriptions'}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <FormItem
                key={field.key}
                label={
                  index === 0
                    ? intl.formatMessage({
                        id: 'product.form.label.descriptions'
                      })
                    : ''
                }
                required={required && index < 1}>
                <DynamicFieldContent>
                  <Form.Item {...field} noStyle>
                    <Input />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <DeleteOutlined onClick={() => remove(field.name)} />
                  ) : null}
                </DynamicFieldContent>
              </FormItem>
            ))}
            <FormItem>
              <Button
                type={'link'}
                icon={<PlusCircleOutlined />}
                onClick={() => add()}>
                <FormattedMessage id={'product.button.addDescription'} />
              </Button>
            </FormItem>
          </>
        )}
      </Form.List>
    </Form>
  )
}

export default injectIntl(ProductForm)
