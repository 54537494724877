import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Alert, Col, Form, Row, Select } from 'antd'

import Button from 'src/components/Button'
import { ActionsRow, FormItem } from 'src/common/styled'
import { ItemsList, ItemsListItem, StyledPanel } from './styled'
import { OPTION_NO_ASSOCIABLE_PRODUCTS } from 'src/common/constants'

const UserFlowAccordion = props => {
  const { intl, index, userFlow, products, onValuesChange, onSubmit } = props
  const [notExistingProducts, setNotExistingProducts] = useState([])

  const [form] = Form.useForm()

  const isProductOptionDisabled = userFlow.productIds.includes(
    OPTION_NO_ASSOCIABLE_PRODUCTS
  )

  useEffect(() => {
    form.setFieldsValue(userFlow)
  }, [form, userFlow])

  const onValuesChangeCallback = useCallback(
    (_, allValues) => {
      const existingProducts = allValues.productIds.includes(OPTION_NO_ASSOCIABLE_PRODUCTS)
        ? [OPTION_NO_ASSOCIABLE_PRODUCTS]
        : allValues.productIds.filter(pId => products.find(p => p.id === +pId))
      const notExistingProducts = allValues.productIds.filter(
        pId =>
          pId !== OPTION_NO_ASSOCIABLE_PRODUCTS &&
          !products.find(p => p.id === +pId)
      )

      setNotExistingProducts(notExistingProducts)

      const newValues = { ...allValues, productIds: existingProducts }
      onValuesChange(_, newValues)
    },
    [products, onValuesChange]
  )

  return (
    <StyledPanel
      {...props}
      header={intl.formatMessage(
        { id: 'productsAssociation.accordion.title' },
        { index }
      )}
      key={index}
      isCompleted={userFlow.productIds.length > 0}>
      <Form
        form={form}
        layout={'vertical'}
        initialValues={userFlow}
        onValuesChange={onValuesChangeCallback}>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              name={'items'}
              label={intl.formatMessage({
                id: 'productsAssociation.form.label.questions'
              })}>
              <ItemsList>
                {userFlow.items.map(item => (
                  <ItemsListItem key={item.questionId}>
                    {item.questionText}
                    <p>{item.answerText}</p>
                  </ItemsListItem>
                ))}
              </ItemsList>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name={'productIds'}
              label={intl.formatMessage({
                id: 'productsAssociation.form.label.productIds'
              })}
              required={true}>
              <Select
                mode={'tags'}
                tokenSeparators={[' ', ',', '\n', '\t']}
                // maxTagCount={3}
              >
                <Select.Option key={OPTION_NO_ASSOCIABLE_PRODUCTS}>
                  <FormattedMessage
                    id={'productsAssociation.option.noAssociableProducts'}
                  />
                </Select.Option>
                {products.map(p => (
                  <Select.Option
                    key={p.id}
                    disabled={
                      isProductOptionDisabled
                    }>{`${p.id} - ${p.text}`}</Select.Option>
                ))}
              </Select>
            </FormItem>
            {notExistingProducts.length > 0 ? (
              <Alert
                message={intl.formatMessage(
                  { id: 'error.notExistingProducts' },
                  { products: notExistingProducts.join(', ') }
                )}
                type={'error'}
                banner
                showIcon
                closable
              />
            ) : null}
          </Col>
        </Row>
      </Form>
      <ActionsRow justify={'end'}>
        <Button disabled={userFlow.productIds.length === 0} onClick={onSubmit}>
          <FormattedMessage id={'productsAssociation.button.saveUserFlow'} />
        </Button>
      </ActionsRow>
    </StyledPanel>
  )
}
export default injectIntl(UserFlowAccordion)
